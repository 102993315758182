import { useState } from 'react'
import { StyledPersonioList, Headline } from './PersonioList.Styled'
import { useEffect } from 'react'
import axios from 'axios'
import Job from './Job/Job'
import { getUUID } from '../../../utility'
import { useSelector } from 'react-redux'
const { XMLParser } = require('fast-xml-parser')

const PersonioList = () => {
  const translation = useSelector((s) => s.translation)
  const [jobs, setJobs] = useState(null)

  useEffect(() => {
    const filter = (jobs) => {
      return jobs.filter((j) => {
        const subcompany = j?.subcompany?.replaceAll(' ', '') === 'DRKKreisverbandCoesfelde.V.'
        const office = j?.office?.replaceAll(' ', '').startsWith('RD-')
        const keywords = j?.keywords?.includes('extern')
        return subcompany && office && keywords
      })
    }

    const fetchJobs = async () => {
      const result = await axios({ method: 'GET', url: 'https://drk-coe.jobs.personio.de/xml' })
      if (!!result?.data) {
        const parser = new XMLParser()
        const jObj = parser.parse(result.data)
        const jobs = jObj?.['workzag-jobs']?.position
        if (!!jobs) {
          setJobs(filter(jobs))
        }
      }
    }
    if (!jobs) {
      fetchJobs()
    }
  }, [jobs])

  if (!Array.isArray(jobs)) return null
  return (
    <StyledPersonioList id="contact">
      <Headline>{translation.application.personio.headline}</Headline>
      {jobs.map((j) => (
        <Job key={getUUID()} data={j} />
      ))}
    </StyledPersonioList>
  )
}

export default PersonioList
