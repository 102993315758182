import styled from 'styled-components'
import Flex from '../../UI/Flex/Flex'
import Image from '../../UI/Image/Image'
import Text from '../../UI/Text/Text'

export const StyledTeam = styled.div`
  height: 600px;
  width: 100vw;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledToggle = styled.div`
  width: 10%;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 20%;
  }
`
export const Headline = styled(Text)`
  font-size: 60px;
  color: ${({ theme }) => theme.color.secondary};
  font-family: 'bold';
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 30px;
  }
`

export const ElementsFlex = styled(Flex)`
  flex-wrap: nowrap;
  gap: 10px;
  align-items: center;
  margin: 30px 0 0 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
`

export const Hint = styled(Text)`
  color: ${({ theme }) => theme.color.secondary};
  width: 60%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    text-align: center;
    width: 100%;
    margin-bottom: 20px;
  }
`
export const Arrow = styled(Image)`
  width: 10%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 35%;
  }
`
export const Bluelight = styled(Text)`
  color: ${({ theme }) => theme.color.secondary};
  width: 10%;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 35%;
    font-size: 20px;
  }
`
