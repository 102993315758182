import { useSelector } from 'react-redux'
import { StyledJob, MetaData, Field, Separator, Location, Info, CTA } from './Job.Styled'
import cta from '../../../../assets/image/application/personio/cta.svg'
import ctaHover from '../../../../assets/image/application/personio/cta-hover.svg'

const Job = ({ data }) => {
  const translation = useSelector((s) => s.translation)
  const { id, name, office, employmentType, schedule } = data

  const getOffices = () => {
    if (!office) return ''
    return translation.application.personio.location + office
  }

  const getEmploymentType = () => {
    if (!employmentType) return false
    if (employmentType === 'permanent') return 'Festanstellung'
    return ''
  }

  const getSchedule = () => {
    if (!schedule) return false
    if (schedule === 'full-time') return 'Vollzeit'
    if (schedule === 'part-time') return 'Teilzeit'
    return false
  }

  const getLink = () => {
    return `https://drk-coe.jobs.personio.de/job/${id}`
  }

  return (
    <StyledJob>
      <Location>{getOffices()}</Location>
      <Info>
        <Field bold>{name}</Field>
        <MetaData>
          {getEmploymentType() && <Field>{getEmploymentType()}</Field>}
          {getEmploymentType() && getSchedule() && <Separator>-</Separator>}
          {getSchedule() && <Field>{getSchedule()}</Field>}
        </MetaData>
      </Info>
      <CTA href={getLink()} target="_blank" src={cta} srcHover={ctaHover} />
    </StyledJob>
  )
}

export default Job
