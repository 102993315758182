import {
  CTA,
  Headline,
  HeadlineImage,
  Hint,
  HintImage,
  StyledFirstScreen,
  CTAWrapper,
} from './FirstScreen.Styled'
import { useSelector } from 'react-redux'

import rdLogo from '../../../assets/image/Rettungsdienst_Logo.png'
import deinRD from '../../../assets/image/Dein_Rettungsdienst.png'
import hint from '../../../assets/image/Slogan.png'

const FirstScreen = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <StyledFirstScreen>
      <Headline>
        <HeadlineImage src={rdLogo} alt="" />
        <HeadlineImage src={deinRD} alt="" />
      </Headline>
      <Hint>
        <HintImage src={hint} alt="" />
        <CTAWrapper>
          <CTA href="#contact">{translation.application.cta}</CTA>
        </CTAWrapper>
      </Hint>
    </StyledFirstScreen>
  )
}

export default FirstScreen
