import { useSelector } from 'react-redux'
import Container from '../UI/Container/Container'
import Layout from '../UI/Layout/Layout'
import {
  Header,
  StyledDataProtection,
  Strong,
  P,
  List,
  ListItem,
  Content,
  HeroFlex,
  HeroImage,
} from './DataProtection.Styled'
import hero from '../../assets/image/dataProtection/hero.jpg'
import rettungsdienst from '../../assets/image/logo-rettungsdienst.png'
import kreisverband from '../../assets/image/logo-kreisverband.png'
import Hero from '../UI/Hero/Hero'

const DataProtection = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <Layout>
      <StyledDataProtection>
        <Hero img={hero}>
          <HeroFlex>
            <HeroImage src={rettungsdienst} />
            <HeroImage src={kreisverband} />
          </HeroFlex>
        </Hero>
        <Container>
          <Content>
            <Header>{translation.dataProtection.headline}</Header>
            <P>
              <Strong>Allgemeines</Strong>
            </P>
            <P>
              Wir, der DRK-Kreisverband Coesfeld e.V., nehmen den Schutz Ihrer personenbezogenen
              Daten und die diesem Schutz dienenden gesetzlichen Verpflichtungen sehr ernst. Unsere
              Datenschutzerklärung erklärt Ihnen deshalb ausführlich, welche sog. personenbezogenen
              Daten von uns bei der Nutzung der Website www.drk-coe.de verarbeitet werden.
            </P>

            <P>
              <Strong>Name und Anschrift des Verantwortlichen</Strong>
            </P>
            <P>
              Verantwortlicher im Sinne der Datenschutzgrundverordnung (DSGVO), des
              Bundesdatenschutzgesetzes (BDSG) sowie sonstiger datenschutzrechtlicher Vorgaben ist
              die
            </P>
            <P>
              DRK-Kreisverband Coesfeld e.V.
              <br />
              Bahnhofstraße 128 in 48653 Coesfeld
              <br />
              Tel: 02541 9442-1000
              <br />
              E-Mail: kgst(at)drk-coe.de
            </P>
            <P>Vertretungsberechtigter: Christoph Schlütermann, Vorstand</P>
            <P>Folgend „Verantwortlicher“ oder „wir“ genannt.</P>
            <P>
              <Strong>Kontaktdaten der Datenschutzbeauftragten</Strong>
            </P>

            <P>
              Unseren Datenschutzbeauftragten erreichen Sie unter:
              <br />
              datenschutz(at)drk-bbs.de
            </P>

            <P>oder postalisch unter</P>

            <P>
              DRK Landesverband Westfalen-Lippe
              <br />
              Betriebswirtschaftliche Beratungs- und Service-GmbH
              <br />
              Hammer Str. 138-140
              <br />
              48153 Münster
            </P>

            <P>
              Bitte beachten Sie, dass Sie über Links auf unserer Website zu anderen Internetseiten
              gelangen können, die nicht von uns, sondern von Dritten betrieben werden. Solche Links
              werden von uns entweder eindeutig gekennzeichnet oder sind durch einen Wechsel in der
              Adresszeile Ihres Browsers erkennbar. Für die Einhaltung der datenschutzrechtlichen
              Bestimmungen und einen sicheren Umgang mit Ihren personenbezogenen Daten auf diesen
              von Dritten betriebenen Internetseiten sind wir nicht verantwortlich.
            </P>
            <P>
              <Strong>Beschwerderecht</Strong>
            </P>

            <P>
              Wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden
              personenbezogenen Daten gegen den Datenschutz verstößt, steht ihnen das Recht auf
              Beschwerde bei einer Datenschutzaufsichtsbehörde gem. Art. 77 DSGVO in Verbindung mit
              § 19 BDSG zu.
            </P>

            <P>Unsere Datenschutzaufsichtsbehörde erreichen Sie unter:</P>

            <P>
              Landesbeauftragte für Datenschutz und Informationsfreiheit
              <br />
              Nordrhein-Westfalen
              <br />
              Postfach 20 04 44
              <br />
              40102 Düsseldorf
            </P>

            <P>
              Tel.: 0211/38424-0
              <br />
              Fax: 0211/38424-10
              <br />
              E-Mail: poststelle@ldi.nrw.de
            </P>

            <P>
              <Strong>Allgemeines zur Datenverarbeitung</Strong>
            </P>

            <P>
              Wir erheben und verwenden personenbezogene Daten unserer Nutzer grundsätzlich nur,
              soweit dies zur Bereitstellung einer funktionsfähigen Website sowie unserer Inhalte
              und Leistungen erforderlich ist. Wir verarbeiten personenbezogene Daten nur, soweit
              dies gesetzlich zulässig ist.
            </P>

            <P>
              Rechtsgrundlage für die Verarbeitung personenbezogener Daten ist der Art. 6 DSGVO,
              hier sind u.a. folgende Rechtsgrundlagen genannt:
            </P>

            <List>
              <ListItem>
                die Verarbeitung auf Basis einer Einwilligung gem. Art. 6 Abs. 1 lit. a DSGVO
              </ListItem>
              <ListItem>
                die Verarbeitung für die Erfüllung eines Vertrags Art. 6 Abs. 1 lit. b DSGVO
              </ListItem>
              <ListItem>
                die Verarbeitung zur Erfüllung einer rechtl. Verpflichtung Art. 6 Abs. 1 lit. c
                DSGVO
              </ListItem>
              <ListItem>
                die Verarbeitung auf Basis eines berechtigten Interesses Art. 6 Abs. 1 lit. f DSGVO
              </ListItem>
            </List>

            <P>
              <Strong>Datenverarbeitung beim Besuch unserer Website</Strong>
            </P>

            <P>
              Wenn Sie unsere Website aufrufen und rein informatorisch nutzen, werden automatisch
              personenbezogene Daten erhoben. Hierbei handelt es sich um folgende Informationen, die
              Ihr Internetbrowser an unseren Webserver übermittelt:
            </P>

            <List>
              <ListItem>IP-Adresse des anfragenden Rechners</ListItem>
              <ListItem>Datum und Uhrzeit Ihres Besuches</ListItem>
              <ListItem>Name und URL der abgerufenen Datei</ListItem>
              <ListItem>Website, von der aus der Zugriff erfolgt (Referrer-URL)</ListItem>
              <ListItem>
                verwendeter Browser und ggf. das Betriebssystem Ihres Rechners sowie
              </ListItem>
              <ListItem>der Name Ihres Access-Providers</ListItem>
              <ListItem>
                Die Informationen werden von Ihrem Browser übermittelt, sofern Sie diesen nicht so
                konfiguriert haben, dass die Übermittlung der Informationen unterdrückt wird.
              </ListItem>
            </List>

            <P>
              Diese Daten werden anonymisiert gespeichert. Nutzerprofile mit Bezug zu bestimmten
              oder bestimmbaren Personen werden nicht erstellt.
            </P>

            <P>
              Die Verarbeitung dieser personenbezogenen Daten erfolgt aus Zwecken der
              Funktionsfähigkeit und Optimierung der Website, sowie zur Gewährleistung der
              Sicherheit unserer informationstechnischen Systeme. Hierin liegt zugleich unser
              berechtigtes Interesse, weswegen die Verarbeitung nach Art. 6 Abs. 1 lit. f DSGVO
              zulässig ist.
            </P>

            <P>
              <Strong>Cookies</Strong>
            </P>

            <P>
              Cookies sind kleine Dateien mit individuellen Kennungen (IDs), die eine Website auf
              dem Endgerät des Nutzers setzt und speichert. Mithilfe von Cookies können
              Website-Betreiber (First-Party-Cookies) oder Dritte (Third-Party-Cookies) dann
              bestimmte Informationen von dem Endgerät des Nutzers auslesen. Individuellen Kennungen
              ermöglichen es, den Nutzer wiederzuerkennen, teilweise über unterschiedliche Websites
              und verschiedene Geräte hinweg.
            </P>

            <P>
              Cookies können für den Betrieb einer Website erforderlich sein, z.B. wenn hierüber der
              Inhalt des Warenkorbs in einem Onlineshop für einen späteren Besuch der Seite
              gespeichert wird.
            </P>

            <P>
              Andere Cookies sind hingegen nicht für den Betrieb einer Website erforderlich, z.B.
              wenn hierüber das Surfverhalten von Nutzern über mehrere Seiten hinweg nachverfolgt
              wird, um interessengerechte Werbung platzieren zu können.
            </P>

            <P>
              Bei erforderlichen Cookies liegt die Verarbeitung Ihrer personenbezogenen Daten gem.
              Art. 6 Abs. 1 lit. f DSGVO in unserem berechtigten Interesse, Ihnen unsere Website mit
              vollem Leistungsumfang anbieten zu können.
            </P>

            <P>Für nicht erforderliche Cookies benötigen wir Ihre Einwilligung.</P>

            <P>
              Hierzu blenden wir, sofern wir nicht erforderliche Cookies verwenden, beim ersten
              Besuch der Website ein Consent-Banner ein und geben ihnen die Möglichkeit, in die
              Verwendung der unterschiedlichen Cookie-Arten einzuwilligen. Diese Einwilligung ist
              freiwillig und für die Nutzung unserer Website nicht erforderlich. Sie können Ihre
              Einwilligung zur Verwendung der Cookies gegebenenfalls jederzeit widerrufen.
            </P>

            <P>
              Sofern Sie in die Verwendung von Cookies eingewilligt haben, verarbeiten wir die Daten
              gem. Art. 6 Abs. 1 lit. a DSGVO.
            </P>
            <P>
              <Strong>Kontaktaufnahme per E-Mail oder Telefon</Strong>
            </P>

            <P>
              Wenn Sie uns eine E-Mail schreiben oder anrufen, werden die von Ihnen dort angegebenen
              personenbezogenen Daten (Name, E-Mail-Adresse, IP-Adresse, Datum und Uhrzeit der
              Eingabe, Name, Mitteilungstext, etc.) von uns verarbeitet. Diese Informationen werden
              im Fall einer E-Mail von Ihrem E-Mail-Client übermittelt und in unseren
              informationstechnischen Systemen gespeichert.
            </P>

            <P>
              Die Datenverarbeitung dient der Beantwortung Ihrer Anfrage und der Gewährleistung der
              Sicherheit unserer informationstechnischen Systeme. Diese Verarbeitungen sind
              rechtmäßig, weil die Beantwortung Ihrer Anfrage sowie der Schutz unserer
              informationstechnischen Systeme berechtigte Interessen im Sinne des Art. 6 Abs. 1 lit.
              f DSGVO darstellen. In Fällen, in denen Ihre Anfrage auf den Abschluss eines Vertrags
              oder eines bestehenden Vertrags abzielt, findet die Verarbeitung auf der Grundlage von
              Art. 6 Abs. 1 lit. b DSGVO statt.
            </P>

            <P>
              Die personenbezogenen Daten werden grds. nur solange gespeichert, wie dies zur
              Beantwortung Ihrer Anfrage erforderlich ist.
            </P>

            <P>
              Bitte beachten Sie, dass generell nicht ausgeschlossen werden kann, dass
              unverschlüsselte E-Mails von Unbefugten während der Übertragung mitgelesen werden
              können. Wir raten Ihnen daher vom Versand unverschlüsselter E-Mails ab, sofern Sie uns
              personenbezogene oder sensible Daten mitteilen möchten. Nutzen Sie im Zweifel den
              Postweg oder rufen Sie uns an.
            </P>
            <P>
              <Strong>Kontaktaufnahme per Onlineformular</Strong>
            </P>

            <P>
              Darüber hinaus bieten wir auf unserer Website verschiedene Onlineformulare an, über
              welche Sie mit uns in Kontakt treten können. Alle Onlineformulare senden die
              Interessenten- und Kundendaten über eine HTTPS-verschlüsselte Verbindung.
            </P>

            <P>
              <Strong>Kontaktformular</Strong>
            </P>

            <P>
              Wir bieten ein allgemeines Kontaktformular an, welches Sie für Anfragen und Anregungen
              aller Art nutzen können.
            </P>

            <P>
              Die hierbei eingegebenen Daten werden dabei von uns verarbeitet. Diese Daten sind für
              die Bearbeitung Ihrer Anfrage und für die Sicherheit unserer informationstechnischen
              Systeme erforderlich. Hierin liegt unser berechtigtes Interesse gem. Art. 6 Abs. 1 lit
              f DSGVO.
            </P>

            <P>
              Die Daten dienen ausschließlich der Beantwortung Ihrer Anfrage und werden gelöscht,
              sobald die Bearbeitung Ihres Anliegens dies nicht mehr erfordert.
            </P>
            <P>
              <Strong>Bewerbungen</Strong>
            </P>

            <P>
              Wenn Sie sich bei uns bewerben, verarbeiten wir Ihre übermittelten Bewerberdaten
              (diese umfassen regelmäßig Personalien, Zeugnisse, weitere Nachweise, Lebenslauf,
              Foto) zur Vertragsanbahnung gem. Art. 6 Abs. 1 lit. b DSGVO und zur Entscheidung über
              die Begründung eines Beschäftigungsverhältnisses gem. Art. 88 DSGVO i. V. m. § 26
              BDSG.
            </P>

            <P>
              Um an einem Bewerbungsverfahren teilzunehmen, ist die Übermittlung der oben genannten
              Daten erforderlich. Sie werden für die Durchführung des Bewerbungsverfahrens benötigt.
              Wenn Sie die erforderlichen Daten nicht angeben, kann Ihre Bewerbung nicht
              berücksichtigt werden.
            </P>

            <P>
              Sollten Sie im Bewerbungsverfahren nicht berücksichtigt werden, löschen wir Ihre
              personenbezogenen Daten 6 Monate nach Abschluss Ihres Bewerbungsverfahrens. Diese
              Löschfrist ermöglicht es uns, gegen uns in dieser Zeit geltend gemachte Ansprüche,
              z.B. aus dem Allgemeinen Gleichstellungsgesetz, abzuwehren. Die Verarbeitung Ihrer
              personenbezogenen Daten in diesem Zeitraum basiert damit auf Art. 6 Abs. 1 lit. f
              DSGVO.
            </P>
            <P>
              <Strong>Rechte der betroffenen Person</Strong>
            </P>

            <P>
              Nach der Datenschutz-Grundverordnung stehen Ihnen folgende Rechte zu: Werden Ihre
              personenbezogenen Daten verarbeitet, so haben Sie das Recht, Auskunft über die zu
              Ihrer Person gespeicherten Daten zu erhalten (Art. 15 DSGVO).
            </P>

            <P>
              Sollten unrichtige personenbezogene Daten verarbeitet werden, steht Ihnen ein Recht
              auf Berichtigung zu (Art. 16 DSGVO).
            </P>

            <P>
              Liegen die gesetzlichen Voraussetzungen vor, so können Sie die Löschung oder
              Einschränkung der Verarbeitung verlangen sowie Widerspruch gegen die Verarbeitung
              einlegen (Art. 17, 18 und 21 DSGVO).
            </P>

            <P>
              Wenn Sie in die Datenverarbeitung eingewilligt haben oder ein Vertrag zur
              Datenverarbeitung besteht und die Datenverarbeitung mithilfe automatisierter Verfahren
              durchgeführt wird, steht Ihnen gegebenenfalls ein Recht auf Datenübertragbarkeit zu
              (Art. 20 DSGVO).
            </P>

            <P>
              Außerdem haben Sie das Recht auf die Beschwerde bei einer Aufsichtsbehörde (Art. 77
              DSGVO).
            </P>

            <P>
              Sollten Sie von Ihren oben genannten Rechten Gebrauch machen, prüft unser Unternehmen,
              ob die gesetzlichen Voraussetzungen hierfür erfüllt sind.
            </P>
            <P>
              <Strong>Automatisierte Entscheidungsfindung</Strong>
            </P>

            <P>
              Automatisierte Entscheidungen im Einzelfall einschließlich Profiling erfolgen nicht.
            </P>
            <P>
              <Strong>Datenlöschung und Speicherdauer</Strong>
            </P>

            <P>
              Grundsätzlich erfolgt eine Löschung der Daten, sobald der Zweck ihrer Erhebung erfüllt
              wurde. Einer Löschung von Daten können jedoch gesetzliche Aufbewahrungsfristen
              entgegenstehen. In diesem Fall dürfen die Daten erst nach Ablauf der Frist vernichtet
              bzw. gelöscht werden.
              <br />
              Einzelne Informationen zur Datenlöschung und Speicherdauer entnehmen Sie den in dieser
              Erklärung aufgeführten Verarbeitungen.
            </P>

            <P>
              <Strong>Übermittlung von personenbezogenen Daten an ein Drittland</Strong>
            </P>

            <P>
              Eine Übermittlung in ein Drittland oder an eine internationale Organisation findet
              vorbehaltlich weiterer Bestimmungen dieser Datenschutzerklärung grds. nicht statt.
            </P>

            <P>
              <Strong>Widerrufsrecht bei Einwilligung</Strong>
            </P>

            <P>
              Falls eine Verarbeitung Ihrer personenbezogenen Daten auf einer entsprechenden
              Einwilligung von Ihnen erfolgt, können Sie die Einwilligung jederzeit gemäß Art. 7
              Abs. 3 S. 4 DSGVO. Die Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
              erfolgten Verarbeitung wird hiervon nicht berührt. Der Widerruf gilt immer nur für die
              nach dem Widerruf geplante Verarbeitung. Der Widerruf ist formlos per Post oder E-Mail
              möglich. Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr
              verarbeiten, es sei denn, eine andere (gesetzliche) Grundlage gestattet dies. Erfolgt
              jedoch ein Widerruf und es liegt kein anderer Erlaubnistatbestand vor, so müssen gemäß
              Art. 17 Abs. 2 lit. b DSGVO die personenbezogenen Daten auf Ihren Wunsch hin
              unverzüglich gelöscht werden. Der Widerruf kann formfrei erfolgen und ist zu richten
              an:
            </P>

            <P>E-Mail: kgst(at)drk-coe.de</P>
          </Content>
        </Container>
      </StyledDataProtection>
    </Layout>
  )
}

export default DataProtection
