import { useSelector } from 'react-redux'
import {
  StyledFooter,
  Column,
  Border,
  Content,
  CustomLink,
  Copyright,
  CustomFlex,
  ImageFlex,
} from './Footer.Styled'
import Flex from '../../Flex/Flex'
import Image from '../../Image/Image'
import logo1 from '../../../../assets/image/logo-rettungsdienst.png'
import logo2 from '../../../../assets/image/logo-kreisverband.png'

const Footer = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <StyledFooter>
      <Border />
      <Content>
        <Flex align={'center'}>
          <Column>
            <ImageFlex wrap="no-wrap" gap="30px" justify={'left'}>
              <Image src={logo1} height="30px" />
              <Image src={logo2} height="30px" />
            </ImageFlex>
          </Column>
          <Column>
            <CustomFlex>
              <CustomLink
                hiddenOnMobile
                href="/datenschutz"
                text={translation.dataProtection.headline}
              />
              <CustomLink hiddenOnMobile href="/impressum" text={translation.imprint.headline} />
              <CustomLink href="/contact" text={translation.contact.headline} />
              <CustomLink href="/application" text={translation.header.nav.application} />
            </CustomFlex>
          </Column>
        </Flex>
        <Copyright text={translation.footer.copyright} />
      </Content>
    </StyledFooter>
  )
}

export default Footer
