import { useState } from 'react'
import { StyledSlider, Slide, Control, Pagination, Point, ControlImage } from './Slider.Styled'
import toLeft from '../../../assets/image/slider/toLeft.png'
import toRight from '../../../assets/image/slider/toRight.png'
import { getUUID } from '../../../utility'

const Slider = ({ height, slideDataArray, renderer, margin }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const move = (moveByAmount) => () => {
    const newIndex = activeIndex + moveByAmount
    if (newIndex < 0) {
      setActiveIndex(slideDataArray.length - 1)
    } else if (newIndex === slideDataArray.length) {
      setActiveIndex(0)
    } else {
      setActiveIndex(newIndex)
    }
  }

  const jump = (index) => () => setActiveIndex(index)

  return (
    <StyledSlider height={height} margin={margin}>
      {slideDataArray.map((data, i) => (
        <Slide active={i === activeIndex} key={getUUID()}>
          {renderer(data)}
        </Slide>
      ))}
      <Control left="0px" onClick={move(-1)}>
        <ControlImage src={toLeft} />
      </Control>
      <Control right="0px" onClick={move(1)}>
        <ControlImage src={toRight} />
      </Control>
      <Pagination className="asdf">
        {slideDataArray.map((d, i) => (
          <Point onClick={jump(i)} active={i === activeIndex} key={getUUID()} />
        ))}
      </Pagination>
    </StyledSlider>
  )
}

export default Slider
