import styled from 'styled-components'
import Flex from '../../UI/Flex/Flex'
import Image from '../../UI/Image/Image'
import Text from '../../UI/Text/Text'

export const StyledInstagram = styled.div``
export const Center = styled.div`
  text-align: center;
`

export const CustomImage = styled(Image)`
  height: 60px;
  margin: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin: 50px 0px;
    height: auto;
    width: 93%;
  }
`

export const FollowText = styled(Text)`
  text-transform: uppercase;
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.secondary};
  width: auto;
`

export const FollowFlex = styled(Flex)`
  margin: 50px 0;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 20px;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
  }
`
