import * as types from './type/type'
import * as translations from './translations'
import * as responsiveState from './responsiveState'
import * as theme from './theme'
import * as token from './token'
import defaultState from './defaultState'

function reducer(state = defaultState, action) {
  switch (action.type) {
    case types.SET_TRANSLATION:
      return translations.set(state, action)
    case types.SET_RESPONSIVE_STATE:
      return responsiveState.set(state, action)
    case types.SET_THEME:
      return theme.set(state, action)
    case types.SET_TOKEN:
      return token.set(state, action)
    default:
      return state
  }
}

export default reducer
