import HelveticaNeueLTProBd from './assets/font/HelveticaNeue/HelveticaNeueLTPro-Bd.otf'
import HelveticaNeueLTProHv from './assets/font/HelveticaNeue/HelveticaNeueLTPro-Hv.otf'
import HelveticaNeueLTProMd from './assets/font/HelveticaNeue/HelveticaNeueLTPro-Md.otf'
import HelveticaNeueLTProRoman from './assets/font/HelveticaNeue/HelveticaNeueLTPro-Roman.otf'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: "headline";
  font-weight: thin;
  src: url(${HelveticaNeueLTProBd}) format("opentype");
}

@font-face {
  font-family: "bold";
  src: url(${HelveticaNeueLTProHv}) format("opentype");
}

@font-face {
  font-family: "regular";
  src: url(${HelveticaNeueLTProRoman}) format("opentype");
}

@font-face {
  font-family: 'nav';
  src: url(${HelveticaNeueLTProMd}) format("opentype");
}

* {
  padding: 0px;
  margin: 0px;
  font-family: "regular", sans-serif;
  font-size: ${({ theme }) => theme.size.md};
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
}

html,
body {
  scroll-behavior: smooth;
  background-color: ${({ theme }) => theme.color.background};
}

.Toastify {
  * {
  font-size: ${({ theme }) => theme.size.sm};
  }
}

.main-content {
  min-height: 100vh;
}

`

export default GlobalStyles
