import { StyledNumbers, Item, Number, Icon, CustomText } from './Numbers.Styled'
import CountUp from 'react-countup'
import Container from '../../UI/Container/Container'
import peopleIcon from '../../../assets/image/aboutUs/people.png'
import carIcon from '../../../assets/image/aboutUs/car.png'
import houseIcon from '../../../assets/image/aboutUs/house.png'
import { useSelector } from 'react-redux'

const Numbers = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Container>
      <StyledNumbers>
        <Item>
          <Number>
            <CountUp end={200} duration={2} delay={1} suffix=" +" />
          </Number>
          <Icon src={peopleIcon} />
          <CustomText text={translation.aboutUs.numbers.text1} />
        </Item>
        <Item>
          <Number>
            <CountUp end={20} duration={2} delay={1} />
          </Number>
          <Icon src={carIcon} />
          <CustomText text={translation.aboutUs.numbers.text2} />
        </Item>
        <Item>
          <Number>
            <CountUp end={8} duration={2} delay={1} />
          </Number>
          <Icon src={houseIcon} />
          <CustomText text={translation.aboutUs.numbers.text3} />
        </Item>
      </StyledNumbers>
    </Container>
  )
}

export default Numbers
