import {
  StyledTeam,
  StyledToggle,
  Headline,
  ElementsFlex,
  Arrow,
  Hint,
  Bluelight,
} from './Team.Styled'
import background from '../../../assets/image/aboutUs/background.jpg'
import arrow from '../../../assets/image/aboutUs/arrow.png'
import { useSelector } from 'react-redux'
import Switch from 'react-ios-switch'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const Toggle = () => {
  const navigate = useNavigate()
  const [checked, setChecked] = useState(false)

  const clicked = () => {
    setChecked(true)
    setTimeout(() => {
      navigate('/application')
    }, 750)
  }

  return (
    <StyledToggle>
      <Switch
        checked={checked}
        onChange={clicked}
        offColor={'rgba(71, 81,120)'}
        onColor={'rgba(11,16,34)'}
        activeText={'On'}
        inActiveText={'Off'}
      />
    </StyledToggle>
  )
}

const Team = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <StyledTeam img={background}>
      <div>
        <Headline text={translation.aboutUs.team.title} />
        <ElementsFlex>
          <Hint text={translation.aboutUs.team.hint} />
          <Arrow src={arrow} />
          <Toggle />
          <Bluelight text={translation.aboutUs.team.bluelight} />
        </ElementsFlex>
      </div>
    </StyledTeam>
  )
}

export default Team
