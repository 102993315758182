import styled from 'styled-components'
import Image from '../../Image/Image'
import Link from '../../Link/Link'

export const StyledHeader = styled.div`
  position: absolute;
  width: 100vw;
  height: 80px;
  z-index: 999;
  background-color: transparent;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    /* display: none; */
    position: fixed;
    background: rgb(0, 212, 255);
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 1) 100%);
  }
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
`

export const Item = styled.div`
  flex: 1 1 0px;
  margin: 0px 15px;
  color: ${({ theme }) => theme.color.font.secondary};
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: none;
  }
`
export const MobileItem = styled.div`
  margin: 25px 15px;
  color: ${({ theme }) => theme.color.font.secondary};
  width: 100%;
`

export const StyledToggle = styled.img`
  height: 40px;
  cursor: pointer;
  display: none;
  position: ${({ open }) => (open ? 'fixed' : 'absolute')};
  right: 20px;
  top: 25px;
  height: 30px;
  z-index: 999;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: block;
  }
`

export const Navigation = styled.div`
  padding: 100px 0px;
  background-color: ${({ theme }) => theme.color.background};
  z-index: 998;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0px;
  left: 0px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-content: flex-start;
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: block;
  }
`

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.font.secondary};
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.size.sm};
  font-family: ${({ active }) => (active ? 'bold' : 'nav')};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    text-align: left;
  }
  ${({ highlight }) =>
    highlight &&
    `
    background-color: red;
    padding: 7px 7px 3px 7px;
  `}
`

export const Logo = styled(Image)`
  height: 40px;
  cursor: pointer;
`
export const MainLogo = styled(Image)`
  height: 40px;
  cursor: pointer;
  margin-left: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    height: 30px;
    position: absolute;
    z-index: 999;
  }
`
