import { StyledTextarea } from './Textarea.Styled'

const Textarea = ({
  onChange,
  value,
  placeholder,
  margin,
  className,
  rows = 3,
  required = false,
}) => {
  return (
    <StyledTextarea
      onChange={(e) => onChange(e.target.value)}
      value={value}
      placeholder={placeholder}
      margin={margin}
      required={required}
      className={className}
      rows={rows}
    />
  )
}

export default Textarea
