import styled from 'styled-components'
import Image from '../Image/Image'

export const StyledHero = styled.div`
  min-height: 100vh;
  width: 100vw;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: top center;
  position: relative;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-image: ${({ mobileImg }) => mobileImg && `url(${mobileImg});`};
  }
`

export const MobileImage = styled(Image)`
  width: 100%;
`
