import Hero from '../UI/Hero/Hero'
import Layout from '../UI/Layout/Layout'
import {
  StyledLocation,
  Headline,
  Center,
  SlideContent,
  SlidePanel,
  CrossWrapper,
  HeadlineWrapper,
  LocationName,
  LocationText,
  SliderWrapper,
  HeroMobileText,
  SeparatorImage,
  CrossImage,
  SlideHeadline,
  FlexWrapper,
  TextToggle,
  ToggleText,
} from './Location.Styled'
import hero from '../../assets/image/locations/hero.png'
import mobileHero from '../../assets/image/locations/mobileHero.png'
import { useSelector } from 'react-redux'
import Container from '../UI/Container/Container'
import logo from '../../assets/image/logo-rettungsdienst.png'
import ascheberg from '../../assets/image/locations/slides/ascheberg.jpg'
import coesfeldkv from '../../assets/image/locations/slides/coesfeld-kv.jpg'
import coesfeldrw from '../../assets/image/locations/slides/coesfeld-rw.jpg'
import billerbeck from '../../assets/image/locations/slides/billerbeck.jpg'
import havixbeck from '../../assets/image/locations/slides/havixbeck.jpg'
import nottuln from '../../assets/image/locations/slides/nottuln.jpg'
import senden from '../../assets/image/locations/slides/senden.jpg'
import luedinghausen from '../../assets/image/locations/slides/luedinghausen.jpg'
import olfen_nordkirchen from '../../assets/image/locations/slides/olfen_nordkirchen.jpg'
import Slider from '../UI/Slider/Slider'
import crossImage from '../../assets/image/locations/cross.png'
import { useState } from 'react'
import toggle from '../../assets/image/toggle.png'
import Image from '../UI/Image/Image'

const Location = () => {
  const translation = useSelector((s) => s.translation)
  const [showText, setShowText] = useState(false)

  const slideImageArray = [
    ascheberg,
    coesfeldkv,
    coesfeldrw,
    billerbeck,
    havixbeck,
    nottuln,
    senden,
    luedinghausen,
    olfen_nordkirchen,
  ]

  const slideDataArray = translation.locations.slides.map((s, i) => ({
    ...s,
    backgroundImage: slideImageArray[i],
    headline: s.headline || translation.locations.slidesDefaultHeadline,
  }))

  const slideRenderer = (data) => {
    return (
      <SlideContent img={data.backgroundImage}>
        <SlidePanel>
          <FlexWrapper>
            <CrossWrapper>
              <CrossImage src={crossImage} />
            </CrossWrapper>
            <HeadlineWrapper>
              <SlideHeadline text={data.headline} />
              <LocationName text={data.location} />
            </HeadlineWrapper>
          </FlexWrapper>
          <TextToggle
            onClick={() => setShowText(data.headline)}
            isVisible={showText !== data.headline}
          >
            <Image src={toggle} height="10px" />
            <ToggleText text={translation.locations.slideTextToggle} />
          </TextToggle>
          <LocationText text={data.text} isVisible={showText === data.headline} />
        </SlidePanel>
      </SlideContent>
    )
  }

  return (
    <Layout>
      <StyledLocation>
        <Hero img={hero} mobileImg={mobileHero}>
          <HeroMobileText text={translation.locations.heroMobileText} />
        </Hero>
        <Container>
          <Headline text={translation.locations.headline} />
          <Center>
            <SeparatorImage src={logo} />
          </Center>
        </Container>
        <SliderWrapper>
          <Slider slideDataArray={slideDataArray} renderer={slideRenderer} />
        </SliderWrapper>
      </StyledLocation>
    </Layout>
  )
}

export default Location
