import styled from 'styled-components'
import Flex from '../UI/Flex/Flex'
import Image from '../UI/Image/Image'

export const StyledImprint = styled.div`
  font-family: $text-font;
  color: ${({ theme }) => theme.color.font.primary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    * {
      font-size: 13px;
    }
  }
`

export const Anchor = styled.a`
  word-break: break-word;
  color: ${({ theme }) => theme.color.font.secondary};
`
export const Header = styled.h1`
  font-size: 40px;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.color.font.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 30px;
  }
`

export const Strong = styled.span`
  font-family: 'bold';
  color: ${({ theme }) => theme.color.font.secondary};
`
export const P = styled.p`
  margin: 10px 0px;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const H3 = styled.h3`
  color: ${({ theme }) => theme.color.font.secondary};
`
export const H2 = styled.h2`
  color: ${({ theme }) => theme.color.font.secondary};
`

export const Content = styled.div`
  margin: 50px auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0 15px;
  }
`

export const HeroFlex = styled(Flex)`
  align-items: center;
  position: absolute;
  bottom: 100px;
  width: 100%;
  gap: 100px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    flex-wrap: wrap;
    gap: 25px;
  }
`

export const HeroImage = styled(Image)`
  height: 60px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 80vw;
    height: auto;
  }
`
