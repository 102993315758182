//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  home: {
    bottom: {
      box: {
        headline: 'Unser DRK Rettungsdienst ist Dein Rettungsdienst.',
        text1:
          'Mit rund 200 Mitarbeiter/-innen besetzen wir im Einsatzbereich des Kreises Coesfeld, mitten im Münsterland zwischen Münster und Dortmund, mehr als 20 Rettungsmittel, verteilt auf 8 Wachstandorten, von denen drei als Lehrrettungswachen anerkannt sind. Die Fahrzeuge sind mit einem Elektropneumatischen Kartsana Tragesystem, Pax Rucksacksystem der neuesten Generation, Corpuls C3 Patientenmonitor und Defibrillator, Medumat Transport & Standard² Beatmungsgeräten, Digitalfunk, mit der Leitstelle gekoppeltem Navigationssystem und vielem mehr top aktuell und umfassend ausgestattet, um unserem Standard in der Sicherheit und Arbeitsergonomie gerecht zu werden (RTW Aufbau von Firma Fahrtec Systeme). Dies gilt auch für unsere Rettungswachen, bei denen besonders großer Wert auf die sozialen Gegebenheiten gelegt ist, um nach den Einsätzen einen Rückzugsort für sich zu haben.',
      },
    },
    instagram: {
      follow: 'Folgt uns auf Instagram, um nichts mehr zu verpassen.',
    },
  },

  aboutUs: {
    hero: 'Wir sind für dich da.',
    numbers: {
      text1: 'MITARBEITER:INNEN',
      text2: 'RETTUNGSMITTEL',
      text3: 'STANDORTE',
    },
    headline1: 'Unser DRK Rettungsdienst ist Dein Rettungsdienst!',
    text1:
      'Mit rund 200 Mitarbeiter/-innen besetzen wir im Einsatzbereich des Kreises Coesfeld, mitten im Münsterland zwischen Münster und Dortmund, mehr als 20 Rettungsmittel, verteilt auf 8 Wachstandorten, von denen drei als Lehrrettungswachen anerkannt sind. Die Fahrzeuge sind mit einem Elektropneumatischen Kartsana Tragesystem, Pax Rucksacksystem der neuesten Generation, Corpuls C3 Patientenmonitor und Defibrillator, Medumat Transport & Standard² Beatmungsgeräten, Digitalfunk, mit der Leitstelle gekoppeltem Navigationssystem und vielem mehr top aktuell und umfassend ausgestattet, um unserem Standard in der Sicherheit und Arbeitsergonomie gerecht zu werden (RTW Aufbau von Firma Fahrtec Systeme). Dies gilt auch für unsere Rettungswachen, bei denen besonders großer Wert auf die sozialen Gegebenheiten gelegt ist, um nach den Einsätzen einen Rückzugsort für sich zu haben.',
    text2:
      'Dein Auftrag bei uns gestaltet sich fast ausschließlich durch Einsatzzeiten auf den Notfallrettungsmitteln (RTW und NEF). Unsere Führungsorganisation ist so strukturiert, dass du als Gruppenführer/-in auf den als Führungsmittel ausgestatteten NEF´s die erste Führung und Koordination des Einsatzes bei besonderen Einsatzlagen übernimmst, bis der/die OrgL (Organisatorische Leiter/-in Rettungsdienst), welcher unserem DRK Rettungsdienst angehört, eintrifft und die Koordination von dir übergeben bekommt. Wir bilden dich sorgfältig zum/-r Gruppenführer/-in Rettungsdienst in Münster aus und bauen die Führungsorganisation auf dich auf.',
    text3:
      'Für deine Dienste im individuellen Schichtbetrieb erhält du ein verlässliches Dienstplanjahresschema, damit Arbeit und Freizeit gut planbar ist. Die jährliche Pflichtfortbildung für den Rettungsdienst findet in Zusammenarbeit mit dem Ärztlichen Leiter Rettungsdienst in Coesfeld statt. Rettungsdienstliche Weiterbildungen laufen meist im nahen Münster. Dein Engagement bei uns in Vollzeit oder Teilzeit, wird mit einem fairen Tarifvertrag des öffentlichen Dienstes (TVöD-VKA), einschließlich aller Zusatzleistungen wie Weihnachtsgeld, Leistungsprämie und zusätzlicher Altersvorsorge vergütet.',
    text4: 'Deinen Wechsel zu uns können wir aktiv unterstützen und honorieren.',
    text5:
      'Es gibt noch vieles mehr zu berichten, deshalb kommen wir am besten in den persönlichen Kontakt, damit du sicher sein kannst,',
    text6: 'dass „Unser DRK Rettungsdienst Dein Rettungsdienst ist.“',
    videoHeadline: 'WIR SIND DEIN RETTUNGSDIENST.',
    afterVideoHeadline: 'Erklärung der Spitzenabdeckung',
    afterVideoText1:
      'Durch die Spitzenabdeckung wird zu jeder Zeit ein vollständig verfügbarer Rettungsdienst im Kreis Coesfeld gewährleistet. Das Konzept ist, dass der Rettungsdienst durch das Ehrenamt unterstützt wird, sobald kein Rettungsmittel des Regelrettungsdienstes verfügbar ist. Diese außerordentliche Situation kann zum Beispiel durch einen Massenanfall von Verletzten (MANV) oder länger als geplant andauernde Einsätze des Regelrettungsdienstes hervorgerufen werden. Die Spitzenabdeckung wird durch mehrere dem Ehrenamt verfügbare KTW und RTW sichergestellt. Als ehrenamtlicher Helfer in der Spitzenabdeckung ist es notwendig entsprechend der zwingend erforderlichen Fahrzeugbesetzung ausgebildet zu sein.',
    afterVideoText2: '(KTW - mind. Rettungssanitäter + Rettungshelfer)',
    afterVideoText3: '(RTW - mind. Notfallsanitäter + Rettungssanitäter)',
    team: {
      title: 'SO KOMMST DU IN UNSER TEAM:',
      hint: 'EINFACH HIER KLICKEN UND MEHR INFORMATIONEN BEKOMMEN.',
      bluelight: 'BLAULICHT',
    },
  },

  locations: {
    headline: 'DER NÄCHSTE STANDORT IST NUR EIN MAUSKLICK ENTFERNT.',
    heroMobileText: 'DIREKT UM DIE ECKE.',
    slideTextToggle: 'mehr anzeigen',
    slidesDefaultHeadline: 'Rettungswache',
    slides: [
      {
        location: 'Ascheberg',
        text: 'Die Rettungswache Ascheberg wurde 2020 erbaut und ist die neueste und modernste Rettungswache des Kreises Coesfeld und entspricht den aktuellsten Standards für Rettungswachen. Der Standort am Randgebiet von Ascheberg sorgt für schnelle und gute Versorgung von Ascheberg und dessen Ortsteilen sowie der Autobahn A1. Das Modell der Ascheberger Rettungswache zeigt, wie alle zukünftigen Rettungswachen im Kreis Coesfeld aussehen sollen. Von den 2 dort stationierten RTW ist einer täglich 24 Stunden besetzt. Die Wache gehört dem Wachbereich Süd an und hat ihr Einzugsgebiet in der Gemeinde Ascheberg mit den Ortsteilen Ascheberg, Davensberg und Herbern ohne die Bauerschaft Nordick sowie der Ortschaft Nordkirchen-Capelle.',
      },
      {
        headline: 'Kreisverband',
        location: 'Coesfeld',
        text: 'Der DRK Kreisverband Coesfeld hat seine Geschäftsstelle in der Bahnhofstraße, in unmittelbarer Nähe zur Rettungswache Coesfeld. Durch den Neubau der Geschäftsstelle in den vergangenen Jahren konnten dort alle relevanten Arbeitsgruppen des Kreisverbandes unter einem Dach zusammenkommen. Neben dem Leiter des Rettungsdienstes, sind dort auch die Abrechnungsstelle des Rettungsdienstes und die DRK Kreis Coesfeld eigenen Unternehmen DRK Kita gGmbH und DRK Sozialdienst gGmbH ansässig. Zudem finden in den Tagungs- und Schulungsräumen die Fortbildungen für den Rettungsdienst und die schulischen Phasen unserer Auszubildenden statt.',
      },
      {
        headline: 'Lehrrettungswache',
        location: 'Coesfeld',
        text: 'Die Rettungswache Coesfeld befindet sich zentral in südöstlicher Lage vom Innenstadtbereich, in unmittelbarer Nähe des Coesfelder Krankenhauses. Oberhalb der Rettungswache befindet sich die Feuerwehr- und Rettungsleitstelle (Kreisleitstelle für den Kreis Coesfeld). Neben den 3 RTW sind 1 NEF und 1 KTW an der Rettungswache stationiert, wovon 2 RTW und das NEF täglich 24 Stunden besetzt sind. Die Lehrrettungswache des Rettungsdienstes Coesfeld hat ihr Einzugsgebiet im Stadtbereich Coesfeld, den Ortsteilen Holtwick und Osterwick sowie der Gemeinde Rosendahl. Die Rettungswache Coesfeld ist dem Wachbereich Nord zugehörig.',
      },
      {
        location: 'Billerbeck',
        text: 'Die Rettungswache Billerbeck hat ihren Standort zentral in Billerbeck an der Holthauser Straße. Als zweite Wache des Wachbereiches Nord, neben der Rettungswache Coesfeld, deckt diese die Stadt Billerbeck ohne dessen Bauerschaften Esking und Temming sowie den Ortsteil Darfeld der Gemeinde Rosendahl ab. Von den 2 RTW ist 1 RTW täglich 24 Stunden besetzt.',
      },
      {
        location: 'Havixbeck',
        text: 'Die Rettungswache Havixbeck liegt am äußeren Bereich des Ortes und bietet damit gute und schnelle Anbindungen Richtung Nottuln, Billerbeck und Münster. Der dort stationierte RTW ist täglich 24 Stunden besetzt. Unter der Zugehörigkeit des Wachbereiches Mitte ist die Rettungswache Havixbeck im Einsatzbereich der Gemeinde Havixbeck und den Dorfbauerschaften Esking und Temming der Stadt Billerbeck zuständig.',
      },
      {
        headline: 'Lehrrettungswache',
        location: 'Nottuln',
        text: 'Die Rettungswache Nottuln ist im Gewerbegebiet von Nottuln angesiedelt. Der Standort bietet mehrere Vorteile zugleich. Die kurzen Wege zu den dort ansässigen Unternehmen und in das Zentrum der Stadt bieten eine schnelle Versorgung. Zudem wird eine schnelle Anbindung zu der Autobahn A43 gewährleistet. An der Wache sind 2 RTW und 1 KTW stationiert, das NEF hat seinen Standort am Krankenhaus Nottuln im direkten Zentrum von Nottuln. Das Einzugsgebiet ist die Gemeinde Nottuln. Wie auch die Rettungswache Billerbeck, ist die Rettungswache Nottuln dem Wachbereich Mitte zugehörig und ist dort die Lehrrettungswache.',
      },
      {
        location: 'Senden',
        text: 'Die Rettungswache Senden hat ihren Standort am Kanalufer des Dortmund-Ems Kanal. Sie ist eine der neuesten Wachen des Kreises Coesfeld und bietet eine sehr gute Anbindung in die Ortsteile von Senden. Von den 2 RTW ist einer 24 Stunden täglich besetzt. Die dem Wachbereich Mitte angehörige Rettungswache hat ihr Einzugsgebiet in der Gemeinde Senden mit den Ortsteilen Bösensell und Ottmarsbocholt sowie dem südlich der Bahnlinie gelegenen Ortsteil von Dülmen-Buldern.',
      },
      {
        headline: 'Lehrrettungswache',
        location: 'Lüdinghausen',
        text: 'Die Rettungswache Lüdinghausen hat ihren Standort im südöstlichen Teil von Lüdinghausen, mit guter Verkehrsanbindung in den Stadtbereich. Neben dem an der Wache stationierten RTW, welcher 24 Stunden täglich besetzt wird, ist ein NEF 24 Stunden täglich am Krankenhaus besetzt. Durch die perfekte Verkehrsanbindung kann auch eine gute Versorgung der Einzugsgebiete Nordkirchen und Olfen gewährleistet werden. Die Rettungswache ist die Lehrrettungswache des Wachbereiches Süd.',
      },
      {
        headline: 'RTW-Standorte',
        location: 'Olfen + Nordkirchen',
        text: 'Zusätzlich werden tagsüber 2 RTW im Wachbereich Süd besetzt. Je einer steht zentral in Olfen und in Nordkirchen. Dies dient dazu, die Hilfsfristen zu gewährleisten. Zudem werden die beiden RTW unterstützend in den umliegenden Orten eingesetzt, falls dort ein RTW im Einsatz und somit nicht verfügbar ist.',
      },
    ],
  },

  equipment: {
    slideTextToggle: 'mehr anzeigen',
    content: {
      headline: 'ENTDECKE UNSERE EINSATZFAHRZEUGE, ALS WÄRST DU VOR ORT.',
      title: 'Einleitung virtuelle Lernmethode',
      text: 'Der Fortschritt durch Technik gewährt vielseitige Möglichkeiten, daher haben wir bei “Dein Rettungsdienst” eine virtuelle Lernmethode integriert. Durch professionelle 3D Scans von Außen und den Innenräumen unserer Fahrzeuge, können Fächer, Schubladen und Bestückungslisten auf mobilen Endgeräten oder auf dem PC studiert werden. Das soll dazu dienen, eine bessere Übersicht und ein ausgeprägteres Verständnis vom Fahrzeug zu erhalten. In Stresssituationen wird das helfen, den Überblick zu bewahren und gezielte Griffe zu den benötigten Materialen zu beschleunigen.',
      mobileHeaderText: 'VOLLAUSSTATTUNG.',
    },
    slides: {
      headline: 'WÄHLE HIER AUS, WELCHES FAHRZEUG DU ERKUNDEN MÖCHTEST.',
      ctaLabel: 'virtuell erkunden',
      ctaLabelDisabled: 'Bald verfügbar',
      vehicle1: 'Notarzteinsatzfahrzeuge',
      text1:
        'Die Notarzteinsatzfahrzeuge (NEF) sind die neuesten Modelle des Modell Kreis Coesfeld. Auf Basis der Volkswagen Transporter wurden die Fahrzeuge durch BINZ ausgebaut. Die als Führungsfahrzeug ausgearbeiteten NEF sind dabei vollumfänglich Ausgestattet.',
      bulletpoints1: [
        'C3 EKG',
        'Corpuls CPR',
        'MEDUMAT Transport',
        'PAX Rucksacksystemen und einem',
        'Columbus Navigationssystem ausgestattet',
        'und vieles mehr',
      ],
      vehicle2: 'RETTUNGSWAGEN',
      text2:
        'Die Rettungswagen (RTW) sind die neuesten Modelle des Modell Kreis Coesfeld. Auf Basis der Mercedes Sprinter wurden die Fahrzeuge durch Fahrtec Sonderfahrzeuge ausgebaut. Dabei wurden Kofferaufbauten konzipiert, welche eine vollständige Ausstattung integrieren.',
      bulletpoints2: [
        'elektrische Trage',
        'C3 EKG',
        'MEDUMAT Transport & Standard 2',
        'PAX Rucksacksysteme',
        'Columbus Navigationssystem',
        'und vieles mehr',
      ],
      vehicle3: 'Krankentransportwagen',
      text3:
        'Die Krankentransportwagen (KTW) sind ausgebaut nach dem Modell Kreis Coesfeld. Der Ausbau erfolgte durch die Hospimobil Ambulance Manufaktur zu einem Notfall KTW.',
      bulletpoints3: [
        'elektrische Trage',
        'elektropneumatischen Tragestuhl',
        'LP15',
        'PAX Rucksacksysteme',
        'Columbus Navigationssystem',
        'und vieles mehr',
      ],
    },
  },

  application: {
    logo: {
      alt: 'Deutsches Rotes Kreuz',
    },
    cta: 'JETZT BEWERBEN',
    text1: 'Unser DRK Rettungsdienst ist Dein Rettungsdienst!',
    text2:
      'Mit rund 200 Mitarbeiter/-innen besetzen wir im Einsatzbereich des Kreises Coesfeld, mitten im Münsterland zwischen Münster und Dortmund, mehr als 20 Rettungsmittel, verteilt auf 8 Wachstandorten, von denen drei als Lehrrettungswachen anerkannt sind.',
    text3:
      'Die Fahrzeuge sind mit einem Elektropneumatischen Kartsana Tragesystem, Pax Rucksacksystem der neuesten Generation, Corpuls C3 Patientenmonitor und Defibrillator, Medumat Transport & Standard² Beatmungsgeräten, Digitalfunk, mit der Leitstelle gekoppeltem Navigationssystem und vielem mehr top aktuell und umfassend ausgestattet, um unserem Standard in der Sicherheit und Arbeitsergonomie gerecht zu werden (RTW Aufbau von Firma Fahrtec Systeme). Das gilt auch für unsere Rettungswachen, bei denen besonders großer Wert auf die sozialen Gegebenheiten gelegt ist, um nach den Einsätzen wieder gut runter kommen zu können.',
    text4:
      'Dein Auftrag bei uns gestaltet sich fast ausschließlich durch Einsatzzeiten auf den Notfallrettungsmitteln (RTW und NEF).',
    text5:
      'Unsere Führungsorganisation ist so strukturiert, dass du als Gruppenführer/-in auf den als Führungsmittel ausgestatteten NEF´s die erste Führung und Koordination des Einsatzes bei besonderen Einsatzlagen übernimmst, bis der/die OrgL (Organisatorische Leiter/-in Rettungsdienst), welcher unserem DRK Rettungsdienst angehört, eintrifft und die Koordination von dir übergeben bekommt. Wir bilden dich sorgfältig zum/-r Gruppenführer/-in Rettungsdienst in Münster aus und bauen die Führungsorganisation auf dich auf.',
    text6:
      'Für deine Dienste im individuellen Schichtbetrieb erhält du ein verlässliches Dienstplanjahresschema, damit Arbeit und Freizeit gut planbar ist. Die jährliche Pflichtfortbildung für den Rettungsdienst findet in Zusammenarbeit mit dem Ärztlichen Leiter Rettungsdienst in Coesfeld statt. Rettungsdienstliche Weiterbildungen laufen meist im nahen Münster.',
    text7:
      'Dein Engagement bei uns in Vollzeit oder Teilzeit, wird mit einem fairen Tarifvertrag des öffentlichen Dienstes (TVöD-VKA), einschließlich aller Zusatzleistungen wie Weihnachtsgeld, Leistungsprämie und zusätzlicher Altersvorsorge vergütet.',
    text8: 'Dein Wechsel zu uns können wir aktiv unterstützen und honorieren.',
    text9:
      'Es gibt noch vieles mehr zu berichten, deshalb kommen wir am besten in den persönlichen Kontakt, damit du sicher sein kannst,',
    text10: 'dass „Unser DRK Rettungsdienst Dein Rettungsdienst ist.“',
    position_cta_text: 'Mehr Informationen',
    personio: {
      headline: 'AKTUELLE STELLENANZEIGEN.',
      location: 'Standort ',
    },
    form: {
      headline: 'Jetzt Bewerben!',
      subject: 'Bewerbung',
      position: {
        label: 'Stelle',
        options: [
          'Notfallsanitäter-/in',
          'Rettungsassistent-/in',
          'Rettungssanitäter-/in',
          'Rettungshelfer-/in',
          'Ausbildung',
          'Sonstige Anfrage',
        ],
      },
      message: {
        label: 'Persönliche Nachricht',
        placeholder: 'Deine persönliche Nachricht an uns',
      },
      cta: 'Absenden',
      upload: {
        info: 'Lade hier deine Bewerbung als PDF hoch. (Maximal 50 MB)',
        reset: 'Datei entfernen',
      },
      success: {
        send: 'Anfrage verarbeitet. Wir werden uns schnellstmöglich bei dir melden.',
      },
      error: {
        send: 'Bei der Verarbeitung Deiner Anfrage ist ein Fehler aufgetreten.',
      },
    },
  },

  header: {
    nav: {
      application: 'Bewerben',
      aboutUs: 'Über uns',
      locations: 'Standorte',
      equipment: 'Ausstattung',
      contact: 'Kontakt',
      dataProtection: 'Datenschutz',
      imprint: 'Impressum',
    },
  },

  contact: {
    headline: 'Kontakt',
    cta: 'Anfrage senden',
    subject: 'Kontaktanfrage',
    person: {
      name: 'Michael Hofmann',
      title: 'LEITER RETTUNGSDIENST',
      phone: '02541 9442-4000',
      fax: '02541 9442-1099',
      mail: 'bewerbung@dein-rettungsdienst.de',
      street: 'Bahnhofstraße 128',
      city: '48653 Coesfeld',
    },
    success: {
      send: 'Kontaktanfrage erfolgreich verschickt.',
    },
    error: {
      send: 'Kontaktanfrage konnte nicht verarbeitet werden.',
    },
  },

  footer: {
    copyright: `© ${new Date().getFullYear()} DEIN RETTUNGSDIENST`,
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  consent: {
    headline: 'Cookie-Hinweis',
    text: 'Diese Website speichert Cookies auf Ihrem Computer. Diese Cookies werden verwendet, um Ihre Website-Erfahrung zu optimieren und Ihnen einen persönlich auf Sie zugeschnittenen Service bereitstellen zu können, sowohl auf dieser Website als auch auf anderen Medienkanälen. Mehr Infos über die von uns eingesetzten Cookies finden Sie in unserer Datenschutzrichtlinie. Bei Ihrem Besuch auf unserer Seite werden Ihre Daten nicht verfolgt. Damit Sie diesen Hinweis aber nicht jedes mal erneut weg klicken müssen wenn Sie auf unserer Seite unterwegs sind, müssen wir dennoch einen kleinen Cookie speichern, damit werden aber keine personenbezogenen Daten von Ihnen assoziert.',
    acceptCTA: 'Akzeptieren',
    declineCTA: 'Nur essentielle Cookies',
  },

  generic: {
    name: 'Name',
    firstname: 'Vorname',
    lastname: 'Nachname',
    company: 'Firma',
    position: 'Stelle',
    mail: 'E-Mail',
    phone: 'Telefonnummer',
    password: 'Password',
    message: 'Nachricht',
    info: {
      street: 'Friedrich-Ebert-Straße 28',
      postcode: '26316',
      city: 'Varel',
    },
  },

  UI: {
    autocomplete: {
      searchError: 'Suche konnte nicht ausgeführt werden.',
      searchEmpty: 'Keine Ergebnisse',
    },
    dropdown: {
      emptySelection: '--- bitte wählen ---',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
