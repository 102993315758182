import FirstScreen from './FirstScreen/FirstScreen'
import Banner from './Banner/Banner'
import { StyledHome } from './Application.Styled'
import Layout from '../UI/Layout/Layout'
import PersonioList from './PersonioList/PersonioList'

const Home = () => {
  return (
    <Layout>
      <StyledHome>
        <FirstScreen />
        <Banner />
        <PersonioList />
      </StyledHome>
    </Layout>
  )
}

export default Home
