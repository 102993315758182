import {
  StyledHeader,
  StyledToggle,
  Navigation,
  CustomLink,
  Item,
  MobileItem,
  ItemWrapper,
  Logo,
  MainLogo,
} from './Header.Styled'
import burger from '../../../../assets/image/burger.png'
import logo1Image from '../../../../assets/image/logo-kreisverband.png'
import logo2Image from '../../../../assets/image/logo-rettungsdienst.png'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate, matchRoutes, useLocation } from 'react-router-dom'
// import Cookies from 'universal-cookie'
// import { setToken } from '../../../../reducer/action/token'
import { getUUID } from '../../../../utility'

const Header = () => {
  const translation = useSelector((s) => s.translation)
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  const location = useLocation()
  const navItems = [
    {
      route: 'application',
      translation: translation.header.nav.application,
      visibleOnDesktop: true,
      highlight: true,
    },
    {
      route: 'aboutus',
      translation: translation.header.nav.aboutUs,
      visibleOnDesktop: true,
    },
    {
      img: logo2Image,
      visibleOnDesktop: true,
    },
    {
      route: 'locations',
      translation: translation.header.nav.locations,
      visibleOnDesktop: true,
    },
    {
      route: 'equipment',
      translation: translation.header.nav.equipment,
      visibleOnDesktop: true,
    },
    {
      route: 'contact',
      translation: translation.header.nav.contact,
      visibleOnDesktop: true,
    },
    {
      route: 'impressum',
      translation: translation.header.nav.imprint,
      visibleOnDesktop: false,
    },
    {
      route: 'datenschutz',
      translation: translation.header.nav.dataProtection,
      visibleOnDesktop: false,
    },
  ]

  // const logout = () => {
  //   const cookies = new Cookies()
  //   cookies.remove('token', { path: '/' })
  //   dispatch(setToken(null))
  //   navigate('/')
  // }

  const matchesRoute = (path) => {
    const matchArray = matchRoutes([{ path }], location)
    return !!matchArray
  }

  return (
    <StyledHeader>
      <ItemWrapper>
        <MainLogo src={logo1Image} onClick={() => navigate('/')} />
        {navItems
          .filter((i) => i.visibleOnDesktop)
          .map(({ img, translation, route, highlight }) => {
            if (img) {
              return (
                <Item key={getUUID()}>
                  <Logo src={img} onClick={() => navigate('/')} />
                </Item>
              )
            }
            return (
              <Item key={getUUID()}>
                <CustomLink
                  highlight={highlight}
                  active={matchesRoute(`/${route}`)}
                  text={translation}
                  onClick={() => navigate(`/${route}`)}
                />
              </Item>
            )
          })}
        {/* <Item></Item> */}
      </ItemWrapper>
      <StyledToggle open={open} src={burger} alt="Menu" onClick={() => setOpen(!open)} />
      {open && (
        <Navigation>
          {/* {account?.user?.isAdmin && (
            <CustomLink text={translation.header.nav.admin} onClick={() => navigate('/admin')} />
          )}
          <CustomLink text={translation.header.nav.logout} onClick={logout} /> */}
          {navItems.map(({ translation, route }) => {
            return (
              <MobileItem key={getUUID()}>
                <CustomLink
                  active={matchesRoute(`/${route}`)}
                  text={translation}
                  onClick={() => navigate(`/${route}`)}
                />
              </MobileItem>
            )
          })}
        </Navigation>
      )}
    </StyledHeader>
  )
}

export default Header
