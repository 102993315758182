import styled from 'styled-components'
import Text from '../Text/Text'

export const StyledInput = styled.input`
  border: ${({ borderColor, theme }) =>
    borderColor ? `thin solid ${borderColor}` : `thin solid ${theme.color.border}`};
  border-radius: ${({ theme }) => theme.border.radius || '0px'};
  color: ${({ theme, color }) => color || theme.color.black};
  background-color: ${({ theme, backgroundColor }) => backgroundColor || theme.color.white};
  padding: 10px 15px;
  font-size: ${({ theme, size }) => size || theme.size.sm};
  display: block;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${({ theme, color }) => color || theme.color.lightblack};
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: ${({ theme, color }) => color || theme.color.lightblack};
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${({ theme, color }) => color || theme.color.lightblack};
  }
`

export const Wrapper = styled.div`
  width: ${({ width }) => width || 'auto'};
  margin: ${({ margin }) => margin || '0px'};
`

export const Label = styled(Text)`
  font-size: ${({ theme }) => theme.size.sm};
  margin-bottom: 5px;
  color: ${({ theme, color }) => color || theme.color.black};
`
