import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import { useSelector } from 'react-redux'
import {
  ButtonWrapper,
  Content,
  Headline,
  Interaction,
  StyledConsent,
  Text,
  CustomButton,
} from './Consent.Styled'
const cookies = new Cookies()

const Consent = () => {
  const translation = useSelector((s) => s.translation)
  const [show, setShow] = useState(false)

  const setConsentCookie = (value) => {
    const expires = new Date()
    expires.setFullYear(expires.getFullYear() + 1)
    cookies.set('consent', value, { expires })
  }

  const accept = () => {
    setConsentCookie('accepted')
    window.location = ''
  }

  const decline = () => {
    setConsentCookie('declined')
    setShow(false)
  }

  useEffect(() => {
    const consent = cookies.get('consent')
    if (!consent) {
      setShow(true)
    }
  }, [])

  if (!show) return <></>
  return (
    <StyledConsent>
      <Content>
        <Headline>{translation.consent.headline}</Headline>
        <Text>{translation.consent.text}</Text>
        <Interaction>
          <ButtonWrapper>
            <CustomButton
              className="accept"
              onClick={accept}
              text={translation.consent.acceptCTA}
            />
          </ButtonWrapper>
          <ButtonWrapper>
            <CustomButton
              className="decline"
              onClick={decline}
              text={translation.consent.declineCTA}
            />
          </ButtonWrapper>
        </Interaction>
      </Content>
    </StyledConsent>
  )
}

export default Consent
