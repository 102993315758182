import { StyledHome, MobileHeroContent } from './Home.Styled'

import Layout from '../UI/Layout/Layout'
import Hero from '../UI/Hero/Hero'
import Image from '../UI/Image/Image'
import heroImage from '../../assets/image/home/hero.png'
import heroMobileImage from '../../assets/image/home/heroMobile.png'
import heroMobileText from '../../assets/image/home/heroMobileText.png'
import Instagram from './Instagram/Instagram'
import Bottom from './Bottom/Bottom'

const Home = () => {
  return (
    <Layout>
      <StyledHome>
        <Hero img={heroImage} mobileImg={heroMobileImage}>
          <MobileHeroContent>
            <Image src={heroMobileText} maxWidth="90%" />
          </MobileHeroContent>
        </Hero>
        <Instagram />
        <Bottom />
      </StyledHome>
    </Layout>
  )
}

export default Home
