import styled from 'styled-components'
import Image from '../../UI/Image/Image'
import Text from '../../UI/Text/Text'
import borderImage from '../../../assets/image/Banner-border.png'

export const StyledBanner = styled.div`
  width: 100%;
  background-color: rgb(229, 0, 19);
  background-size: auto 30vh;
  z-index: 1;
  right: 0px;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
`

export const Woman = styled.div`
  z-index: 2;
  position: absolute;
  right: 0px;
  width: 30%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`

export const WomanImage = styled(Image)`
  max-width: 100%;
  margin-bottom: 20%;
`

export const Border = styled.div`
  background-image: url(${borderImage});
  position: absolute;
  top: -60px;
  width: 100%;
  height: 60px;
  border: none !important;
`

export const ContentWrapper = styled.div`
  width: 70%;
  margin-left: 5vw;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 90%;
    margin-left: 5%;
  }
`

export const TextWrapper = styled.div`
  width: 80%;
  display: inline-block;
  margin-top: 100px;
  margin-bottom: 50px;
  text-align: justify;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 100%;
    margin: 50px 0px;
  }
`

export const CustomText = styled(Text)`
  color: ${({ theme }) => theme.color.secondary};
  font-size: 18px;
  line-height: 1.5;
  ${({ bold }) => (bold ? 'font-family: "bold";' : '')}
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 14px;
  }
`

export const PositionCTAWrapper = styled.div`
  text-align: center;
  width: 100%;
  margin-bottom: 50px;
`

export const PositionCTA = styled.a`
  text-transform: uppercase;
  color: ${({ theme }) => theme.color.secondary};
  padding: 10px 60px;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.color.secondary};
  display: inline-block;
  font-size: 20px;
  cursor: pointer;
  text-decoration: none;
`
