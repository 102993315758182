const de = require('../shared-resources/translations/de')
const getTheme = require('../shared-resources/theme')

const defaultState = {
  translation: de.translation,
  currentLang: de.langKey,
  theme: getTheme(),
  account: null,
  token: null,
  cart: [],
}

export default defaultState
