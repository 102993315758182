import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import GlobalStyle from './Global.Styled'
import { ThemeProvider } from 'styled-components'
import getTheme from './shared-resources/theme'
import Consent from './components/Consent/Consent'
import Home from './components/Home/Home'
import Application from './components/Application/Application'
import Imprint from './components/Imprint/Imprint'
import Datenschutz from './components/DataProtection/DataProtection'
import AboutUs from './components/AboutUs/AboutUs'
import Locations from './components/Locations/Locations'
import Equipment from './components/Equipment/Equipment'
import Contact from './components/Contact/Contact'

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={getTheme()}>
        <Consent />
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Home />} />
            <Route path="aboutus" element={<AboutUs />} />
            <Route path="locations" element={<Locations />} />
            <Route path="equipment" element={<Equipment />} />
            <Route path="application" element={<Application />} />
            <Route path="contact" element={<Contact />} />
            <Route path="impressum" element={<Imprint />} />
            <Route path="datenschutz" element={<Datenschutz />} />
            <Route path={'*'} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  )
}

export default App
