import Layout from '../UI/Layout/Layout'
import {
  StyledEquipment,
  Center,
  Content,
  Headline,
  Box,
  CustomText,
  SliderHeadline,
  SlideContent,
  SlidePanel,
  HeadlineWrapper,
  VehicleName,
  LocationText,
  CTA,
  SliderWrapper,
  MobileContentImage,
  HeroImage,
  HeroText,
  HeroFlex,
  TextToggle,
  ToggleText,
  SubHeroImage,
} from './Equipment.Styled'
import Hero from '../UI/Hero/Hero'
import Image from '../UI/Image/Image'
import heroImage from '../../assets/image/equipment/hero.png'
import heroMobileImage from '../../assets/image/equipment/hero_mobile.png'
import logoKreisverband from '../../assets/image/logo-kreisverband.png'
import contentBackground from '../../assets/image/equipment/content-background.png'
import contentBackgroundMobile from '../../assets/image/equipment/content-background-mobile.png'
import { useSelector } from 'react-redux'
import nef from '../../assets/image/equipment/slides/nef.png'
import rtw from '../../assets/image/equipment/slides/rtw.png'
import nefMobile from '../../assets/image/equipment/slides/nef_mobile.png'
import rtwMobile from '../../assets/image/equipment/slides/rtw_mobile.png'
import ktw from '../../assets/image/equipment/slides/ktw.png'
import ktwMobile from '../../assets/image/equipment/slides/ktw_mobile.png'
import crossImage from '../../assets/image/Rettungsdienst_Logo.png'
import Slider from '../UI/Slider/Slider'
import toggle from '../../assets/image/toggle.png'
import { useState } from 'react'

const Equipment = () => {
  const translation = useSelector((s) => s.translation)
  const [showText, setShowText] = useState(false)

  const slideDataArray = [
    {
      backgroundImage: nef,
      backgroundImageMobile: nefMobile,
      vehicle: translation.equipment.slides.vehicle1,
      text: translation.equipment.slides.text1,
      bulletpoints: translation.equipment.slides.bulletpoints1,
      link: 'https://my.matterport.com/show/?m=ntdLY5aW44u',
    },
    {
      backgroundImage: rtw,
      backgroundImageMobile: rtwMobile,
      vehicle: translation.equipment.slides.vehicle2,
      text: translation.equipment.slides.text2,
      bulletpoints: translation.equipment.slides.bulletpoints2,
      link: 'https://my.matterport.com/show/?m=N2QVS9tdBUD',
    },
    {
      backgroundImage: ktw,
      backgroundImageMobile: ktwMobile,
      vehicle: translation.equipment.slides.vehicle3,
      text: translation.equipment.slides.text3,
      bulletpoints: translation.equipment.slides.bulletpoints3,
      link: 'https://my.matterport.com/show/?m=LQTYgJYwokM',
    },
  ]

  const slideRenderer = (data) => {
    const click = (link) => () => window.open(link, '_blank')

    return (
      <SlideContent img={data.backgroundImage} imgMobile={data.backgroundImageMobile}>
        <SlidePanel>
          <HeadlineWrapper>
            <VehicleName text={data.vehicle} />
          </HeadlineWrapper>
          <TextToggle
            onClick={() => setShowText(data.vehicle)}
            isVisible={showText !== data.vehicle}
          >
            <Image src={toggle} height="10px" />
            <ToggleText text={translation.equipment.slideTextToggle} />
          </TextToggle>
          <LocationText text={data.text} isVisible={showText === data.vehicle} />
          {data.link && (
            <CTA onClick={click(data.link)}>{translation.equipment.slides.ctaLabel}</CTA>
          )}
          {!data.link && <CTA disabled={true}>{translation.equipment.slides.ctaLabelDisabled}</CTA>}
        </SlidePanel>
      </SlideContent>
    )
  }

  return (
    <Layout>
      <StyledEquipment>
        <Hero img={heroImage} mobileImg={heroMobileImage}>
          <HeroFlex>
            <HeroImage src={crossImage} />
            <HeroText text={translation.equipment.content.mobileHeaderText} />
          </HeroFlex>
        </Hero>
        <Center>
          <SubHeroImage src={logoKreisverband} />
        </Center>
        <MobileContentImage src={contentBackgroundMobile} />
        <Content img={contentBackground}>
          <Headline text={translation.equipment.content.headline} />
          <Box>
            <CustomText text={translation.equipment.content.title} font="bold" />
            <CustomText text={translation.equipment.content.text} margin={'25px 0 0 0'} />
          </Box>
        </Content>
        <SliderHeadline text={translation.equipment.slides.headline} />
        <SliderWrapper>
          <Slider height="700px" slideDataArray={slideDataArray} renderer={slideRenderer} />
        </SliderWrapper>
      </StyledEquipment>
    </Layout>
  )
}

export default Equipment
