import styled from 'styled-components'
import Image from '../UI/Image/Image'
import Text from '../UI/Text/Text'

export const StyledSocial = styled.div`
  position: fixed;
  right: 10px;
  z-index: 999;
  top: 30vh;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`

export const CustomText = styled(Text)`
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.secondary};
  pointer-events: none;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
`

export const CustomImage = styled(Image)`
  display: block;
`

export const TextImage = styled(Image)`
  width: 15px;
  margin: 10px 0 0 0;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 12px;
  }
`
