import { useSelector } from 'react-redux'
import woman from '../../../assets/image/Person.png'
import {
  ContentWrapper,
  // PositionCTA,
  // PositionCTAWrapper,
  StyledBanner,
  TextWrapper,
  CustomText,
  Woman,
  WomanImage,
  Border,
} from './Banner.Styled'

const Banner = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <StyledBanner>
      <Woman>
        <WomanImage src={woman} alt="" />
      </Woman>
      <Border />
      <ContentWrapper>
        <TextWrapper>
          <CustomText text={translation.application.text1} />
          <CustomText text={translation.application.text2} margin="20px 0 0 0" />
          <CustomText text={translation.application.text3} />
          <CustomText text={translation.application.text4} margin="20px 0 0 0" />
          <CustomText text={translation.application.text5} />
          <CustomText text={translation.application.text6} margin="20px 0 0 0" />
          <CustomText text={translation.application.text7} />
          <CustomText text={translation.application.text8} margin="20px 0 0 0" />
          <CustomText text={translation.application.text9} margin="20px 0 0 0" />
          <CustomText bold text={translation.application.text10} />
        </TextWrapper>
      </ContentWrapper>
      {/* <PositionCTAWrapper>
        <PositionCTA
          href="https://dein-rettungsdienst-de-homepage-storage.s3.eu-central-1.amazonaws.com/RD_Stellenanzeige_Notfallsanitaeter.pdf"
          rel="noreferrer"
          target="_blank"
          className="position-cta"
        >
          {translation.application.position_cta_text}
        </PositionCTA>
      </PositionCTAWrapper> */}
    </StyledBanner>
  )
}

export default Banner
