import slideToggle from '../../../../assets/image/hero/slide_toggle.png'
import { StyledScrollToggle, Toggle } from './ScrollToggle.Styled'
import { useEffect, useState } from 'react'

const ScrollToggle = () => {
  const [invertToggle, setInvertToggle] = useState(false)
  const [prevScrollHeight, setPrevScrollHeight] = useState(0)
  const [showToggle, setShowToggle] = useState(true)

  const evaluate = () => {
    setShowToggle(
      window.pageYOffset < prevScrollHeight || window.pageYOffset < window.innerHeight / 5,
    )
    setPrevScrollHeight(window.pageYOffset)
    const newInverteState = window.innerHeight / 5 <= window.pageYOffset
    if (newInverteState !== invertToggle) setInvertToggle(newInverteState)
  }

  useEffect(() => {
    document.addEventListener('scroll', evaluate)
    return () => {
      window.removeEventListener('scroll', evaluate)
    }
  })

  const clicked = () => window.scroll(0, invertToggle ? 0 : window.innerHeight)

  return (
    <StyledScrollToggle onMouseOver={() => setShowToggle(true)}>
      {showToggle && <Toggle invert={invertToggle} onClick={clicked} src={slideToggle} />}
    </StyledScrollToggle>
  )
}

export default ScrollToggle
