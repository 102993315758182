import styled from 'styled-components'
import Image from '../../UI/Image/Image'
import Text from '../../UI/Text/Text'

export const StyledNumbers = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 75px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin: 50px 0px;
    flex-wrap: wrap;
    gap: 50px;
  }
`

export const Item = styled.div`
  flex: 1 1 0px;
  text-align: center;
`

export const Number = styled.div`
  color: ${({ theme }) => theme.color.secondary};
  font-family: 'bold';
  color: ${({ theme }) => theme.color.danger};
  * {
    font-family: 'nav';
    font-size: 60px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    * {
      font-size: 40px;
    }
  }
`

export const Icon = styled(Image)`
  height: 80px;
  margin: 20px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 60px;
  }
`

export const CustomText = styled(Text)`
  color: ${({ theme }) => theme.color.danger};
  font-size: ${({ theme }) => theme.size.md};
  text-align: center;
  letter-spacing: 3px;
  font-family: 'bold';
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
`
