import styled from 'styled-components'
import Text from '../../UI/Text/Text'

export const StyledBottom = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: top center;
  position: relative;
  margin-bottom: 500px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-image: ${({ img, mobileImg }) => (mobileImg ? `url(${mobileImg})` : `url(${img})`)};
  }
`

export const Box = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  position: absolute;
  width: 80%;
  bottom: -150px;
  padding: 75px;
  border-radius: 30px;
  margin-left: 10%;
  box-sizing: border-box;
  box-shadow: 0px 0px 13px 0px #000000;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(100% - 30px);
    padding: 20px;
    margin-left: 15px;
    bottom: -40%;
  }
`

export const BoxText = styled(Text)`
  color: ${({ theme }) => theme.color.secondary};
  line-height: 1.5;
`
