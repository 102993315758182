import Hero from '../UI/Hero/Hero'
import {
  StyledAboutUs,
  Center,
  CustomHeroText,
  SeparatorImage,
  VideoHeadline,
  Headline,
  CustomText,
} from './AboutUs.Styled'
import hero from '../../assets/image/aboutUs/hero.jpg'
import heroMobile from '../../assets/image/aboutUs/hero-mobile.png'
import logo1 from '../../assets/image/logo-kreisverband.png'
import logo2 from '../../assets/image/logo-rettungsdienst.png'
import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'
import Numbers from './Numbers/Numbers'
import Container from '../UI/Container/Container'
import Video from './Video/Video'
import Team from './Team/Team'

const AboutUs = () => {
  const translation = useSelector((s) => s.translation)

  return (
    <Layout>
      <StyledAboutUs>
        <Hero img={hero} mobileImg={heroMobile}>
          <CustomHeroText text={translation.aboutUs.hero} />
        </Hero>
        <Numbers />
        <Container>
          <Headline text={translation.aboutUs.headline1} />
          <CustomText text={translation.aboutUs.text1} />
          <CustomText text={translation.aboutUs.text2} />
          <CustomText text={translation.aboutUs.text3} />
          <CustomText text={translation.aboutUs.text4} />
          <CustomText text={translation.aboutUs.text5} />
          <CustomText text={translation.aboutUs.text6} font="bold" />
          <Center>
            <SeparatorImage src={logo1} />
          </Center>
          <VideoHeadline text={translation.aboutUs.videoHeadline} />
        </Container>
        <Video />
        <Container>
          <Center>
            <SeparatorImage src={logo2} />
          </Center>
          <Headline text={translation.aboutUs.afterVideoHeadline} />
          <CustomText text={translation.aboutUs.afterVideoText1} margin="25px 0 0 0" />
          <CustomText text={translation.aboutUs.afterVideoText2} margin="0" />
          <CustomText text={translation.aboutUs.afterVideoText3} margin="0 0 75px 0" />
        </Container>
        <Team />
      </StyledAboutUs>
    </Layout>
  )
}

export default AboutUs
