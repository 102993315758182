import { StyledVideo, Overlay, CustomVideo } from './Video.Styled'
import video from '../../../assets/video/aboutUs/video.mp4'
import play from '../../../assets/image/aboutUs/play.png'
import { useRef, useState } from 'react'
import Image from '../../UI/Image/Image'
import thumbnailDesktop from '../../../assets/image/aboutUs/thumbnail-desktop.jpg'
import thumbnailMobile from '../../../assets/image/aboutUs/thumbnail-mobile.jpg'

const Video = () => {
  const ref = useRef(null)
  const [playing, setPlaying] = useState(false)

  const start = () => {
    ref.current.play()
    setPlaying(true)
  }

  return (
    <StyledVideo>
      <CustomVideo ref={ref} controls={playing} controlsList="nodownload">
        <source src={video} type="video/mp4" />
      </CustomVideo>
      {!playing && (
        <Overlay onClick={start} thumbnail={thumbnailDesktop} thumbnailMobile={thumbnailMobile}>
          <Image src={play} height="75px" />
        </Overlay>
      )}
    </StyledVideo>
  )
}

export default Video
