import { StyledHeroText, CustomText } from './HeroText.Styled'

const HeroText = ({ text, bottom, top, className }) => {
  return (
    <StyledHeroText bottom={bottom} top={top} className={className}>
      <CustomText text={text} className={className} />
    </StyledHeroText>
  )
}

export default HeroText
