import styled from 'styled-components'

export const StyledFeed = styled.div`
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  justify-content: center;
`

export const Tile = styled.a`
  width: calc(350px - 20px);
  height: calc(350px - 20px);
  display: block;
  background-color: ${({ theme }) => theme.color.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: calc(325px - 20px);
    height: calc(325px - 20px);
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: calc(250px - 20px);
    height: calc(250px - 20px);
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: calc(33vw - 20px);
    height: calc(33vw - 20px);
  }
`

export const TileImage = styled.div`
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
`
