import { StyledHero } from './Hero.Styled'
import ScrollToggle from './ScrollToggle/ScrollToggle'

const Hero = ({ img, mobileImg, children, className }) => {
  return (
    <StyledHero img={img} mobileImg={mobileImg} className={className}>
      {children}
      <ScrollToggle />
    </StyledHero>
  )
}

export default Hero
