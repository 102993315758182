import styled from 'styled-components'

export const StyledHome = styled.div``

export const MobileHeroContent = styled.div`
  display: none;
  text-align: center;
  padding-top: 20vh;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`
