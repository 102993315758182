import { StyledSocial, CustomImage, TextImage } from './Social.Styled'
import facebook from '../../assets/image/social/facebook.png'
import instagram from '../../assets/image/social/instagram.png'
import text from '../../assets/image/social/text.png'

const Social = () => {
  return (
    <StyledSocial>
      <CustomImage
        onClick={() =>
          window.open('https://www.facebook.com/dein.rettungsdienst', '_blank').focus()
        }
        src={facebook}
        height="30px"
        margin="10px 0 0 0"
      />
      <CustomImage
        onClick={() =>
          window.open('https://www.instagram.com/dein.rettungsdienst/', '_blank').focus()
        }
        src={instagram}
        height="30px"
        margin="10px 0 0 0"
      />
      <TextImage src={text} />
    </StyledSocial>
  )
}

export default Social
