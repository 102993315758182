import styled from 'styled-components'
import borderImage from '../../../../assets/image/Banner-border.png'
import Link from '../../../UI/Link/Link'
import Text from '../../../UI/Text/Text'

export const StyledFooter = styled.div`
  background-color: ${({ theme }) => theme.color.background};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    /* display: none; */
  }
`

export const Content = styled.div`
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    padding: 15px;
  }
`

export const Column = styled.div`
  flex: 0 0 50%;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    flex: 1 1 0;
  }
`

export const Border = styled.div`
  background-image: url(${borderImage});
  background-size: auto 30px;
  width: 100%;
  height: 30px;
  border: none !important;
`

export const ImageFlex = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 30px;
  justify-content: left;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap;
    margin-bottom: 25px;
    margin-top: 15px;
  }
`
export const CustomFlex = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 30px;
  justify-content: right;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }
`

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.secondary};
  text-transform: uppercase;
  font-size: 14px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: calc(50vw - 20px);
    display: ${({ hiddenOnMobile }) => (hiddenOnMobile ? 'none' : 'block')};
    text-align: center;
  }
`

export const Copyright = styled(Text)`
  font-size: 8px;
  text-align: center;
  color: ${({ theme }) => theme.color.secondary};
  margin: 40px 0 0 0;
`
