import Container from '../../UI/Container/Container'
import { StyledInstagram, Center, CustomImage, FollowText, FollowFlex } from './Instagram.Styled'
import logo from '../../../assets/image/logo-rettungsdienst.png'
import followCTAImage from '../../../assets/image/home/follow-cta.png'
import Feed from './Feed/Feed'
import { useEffect, useState } from 'react'
import { get } from '../../../interface/instagram'
import Image from '../../UI/Image/Image'
import { useSelector } from 'react-redux'

const Instagram = () => {
  const [items, setItems] = useState(null)
  const translation = useSelector((s) => s.translation)

  useEffect(() => {
    const pullItems = async () => {
      const items = await get()
      if (items) {
        setItems(items)
      }
    }
    if (!items) {
      pullItems()
    }
  }, [items])

  return (
    <StyledInstagram>
      {items && (
        <Container>
          <Center>
            <CustomImage src={logo} />
          </Center>
          <Feed items={items} />
          <Center>
            <FollowFlex>
              <FollowText text={translation.home.instagram.follow} />
              <a
                href="https://www.instagram.com/dein.rettungsdienst/"
                target="_blank"
                rel="noreferrer"
              >
                <Image src={followCTAImage} height="35px" />
              </a>
            </FollowFlex>
          </Center>
        </Container>
      )}
    </StyledInstagram>
  )
}

export default Instagram
