import styled from 'styled-components'
import Flex from '../Flex/Flex'
import Image from '../Image/Image'

export const StyledSlider = styled.div`
  height: ${({ height }) => height || '600px'};
  position: relative;
  margin: ${({ margin }) => margin || '50px 0px'};
`

export const Slide = styled.div`
  position: relative;
  width: 90%;
  margin-left: 5%;
  display: ${({ active }) => (active ? 'block' : 'none')};
  overflow: hidden;
  height: 100%;
`

export const Control = styled.div`
  position: absolute;
  height: 100%;
  left: ${({ left }) => left || 'auto'};
  right: ${({ right }) => right || 'auto'};
  display: flex;
  align-items: center;
  cursor: pointer;
  top: 0px;
`

export const ControlImage = styled(Image)`
  height: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 30px;
  }
`

export const Pagination = styled(Flex)`
  justify-content: center;
  margin-top: 10px;
`

export const Point = styled.div`
  height: 12.5px;
  width: 12.5px;
  border-radius: 15px;
  border: thin solid ${({ theme }) => theme.color.secondary};
  background-color: ${({ theme, active }) => (active ? theme.color.secondary : 'transparent')};
  margin: 10px;
  cursor: pointer;
`
