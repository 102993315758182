import styled from 'styled-components'

export const StyledVideo = styled.div`
  height: 600px;
  width: 100%;
  position: relative;
`

export const CustomVideo = styled.video`
  height: 100%;
  width: 100%;
`

export const Overlay = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  background-image: ${({ thumbnail }) => `url(${thumbnail})`};
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-image: ${({ thumbnailMobile }) => `url(${thumbnailMobile})`};
  }
`
