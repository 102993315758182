import {
  StyledContact,
  FormWrapper,
  CustomInput,
  CTA,
  HeaderImageWrapper,
  NoBorderInput,
  NoBorderTextarea,
  HeaderImage,
  CTAFlex,
  PersonWrapper,
  PersonName,
  PersonText,
  PersonContent,
  ElementWrapper,
  ElementHelper,
} from './Contact.Styled'
import Layout from '../UI/Layout/Layout'
import Hero from '../UI/Hero/Hero'
import heroImage from '../../assets/image/contact/hero.jpg'
import personImage from '../../assets/image/contact/person.png'
import { useState } from 'react'
import Flex from '../UI/Flex/Flex'
import Form from '../UI/Form/Form'
import Image from '../UI/Image/Image'
import { useSelector } from 'react-redux'
import kreisverbandImage from '../../assets/image/logo-kreisverband.png'
import rettungsdienstImage from '../../assets/image/logo-rettungsdienst.png'
import { send } from '../../interface/contact'
import toast, { TYPE } from '../../toast'

const Contact = () => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')
  const [mail, setMail] = useState('')
  const [message, setMessage] = useState('')

  const onSubmit = async () => {
    const status = await send({ firstname, lastname, mail, message })
    if (status) {
      toast(TYPE.SUCCESS, translation.contact.success.send)
      setFirstname('')
      setLastname('')
      setMail('')
      setMessage('')
    } else {
      toast(TYPE.ERROR, translation.contact.error.send)
    }
  }

  return (
    <Layout>
      <StyledContact>
        <Hero img={heroImage}>
          <ElementWrapper>
            <ElementHelper>
              <PersonWrapper backgroundImage={personImage}>
                <PersonContent>
                  <PersonName text={translation.contact.person.name} />
                  <PersonText text={translation.contact.person.title} />
                  <PersonText
                    margin="20px 0 0 0"
                    text={'Tel: ' + translation.contact.person.phone}
                  />
                  <PersonText text={'Fax: ' + translation.contact.person.fax} />
                  <PersonText text={'Mail: ' + translation.contact.person.mail} />
                  <PersonText margin="20px 0 0 0" text={translation.contact.person.street} />
                  <PersonText text={translation.contact.person.city} />
                </PersonContent>
              </PersonWrapper>
            </ElementHelper>
            <ElementHelper>
              <FormWrapper>
                <HeaderImageWrapper>
                  <HeaderImage src={rettungsdienstImage} />
                </HeaderImageWrapper>
                <Form onSubmit={onSubmit}>
                  <Flex justify={'space-between'} gap="10px">
                    <CustomInput
                      required
                      value={firstname}
                      onChange={setFirstname}
                      label={translation.generic.firstname}
                      labelColor={theme.color.font.secondary}
                    />
                    <CustomInput
                      required
                      value={lastname}
                      onChange={setLastname}
                      label={translation.generic.lastname}
                      labelColor={theme.color.font.secondary}
                    />
                  </Flex>
                  <NoBorderInput
                    required
                    value={mail}
                    onChange={setMail}
                    label={translation.generic.mail}
                    labelColor={theme.color.font.secondary}
                  />
                  <NoBorderTextarea
                    required
                    value={message}
                    onChange={setMessage}
                    placeholder={translation.generic.message}
                    rows={7}
                  />
                  <CTAFlex gap="20px" margin="20px 0 0 0" wrap="no-wrap" align={'center'}>
                    <CTA text={translation.contact.cta} type="submit" />
                    <Image src={kreisverbandImage} height={'30px'} />
                  </CTAFlex>
                </Form>
              </FormWrapper>
            </ElementHelper>
          </ElementWrapper>
        </Hero>
      </StyledContact>
    </Layout>
  )
}

export default Contact
