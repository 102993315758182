import styled from 'styled-components'

export const StyledPersonioList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 50%;
  margin: auto;
  padding: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 80%;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 90%;
  }
`

export const Headline = styled.div`
  text-align: center;
  font-family: 'bold';
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.blue};
  margin-bottom: 50px;
`
