import styled from 'styled-components'
import Button from '../UI/Button/Button'
import Flex from '../UI/Flex/Flex'
import Image from '../UI/Image/Image'
import Input from '../UI/Input/Input'
import Text from '../UI/Text/Text'
import Textarea from '../UI/Textarea/Textarea'

export const StyledContact = styled.div``

export const ElementWrapper = styled.div`
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 5vw;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    gap: 2.5vw;
    flex-wrap: wrap;
    padding: 100px 0;
  }
`

export const ElementHelper = styled.div`
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 100%;
  }
`

export const PersonWrapper = styled.div`
  border-radius: 10px;
  padding: 25px;
  padding-top: 50px;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: contain;
  background-repeat: no-repeat;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    background-position: center;
    background-size: cover;
    width: 350px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
  }
`

export const PersonName = styled(Text)`
  font-family: 'bold';
  font-size: ${({ theme }) => theme.size.lg};
  color: ${({ theme }) => theme.color.font.secondary};
`

export const PersonText = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.sm};
`

export const PersonContent = styled.div`
  width: 450px;
  margin-top: 300px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: auto;
  }
`

export const FormWrapper = styled.div`
  border-radius: 25px;
  margin-top: 100px;
  padding: 25px;
  padding-top: 50px;
  background-color: ${({ theme }) => theme.color.background};
  position: relative;
  margin: 100px 15px 0px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 350px;
  }
`

export const CustomInput = styled(Input)`
  flex: 0 0 calc(50% - 10px);
  border: none;
`

export const CTA = styled(Button)`
  background-color: ${({ theme }) => theme.color.danger};
  color: ${({ theme }) => theme.color.font.secondary};
  border-radius: 50px;
  font-size: ${({ theme }) => theme.size.sm};
  padding: 10px;
`

export const HeaderImageWrapper = styled.div`
  position: absolute;
  pointer-events: none;
  top: -25px;
  text-align: center;
  width: 100%;
  left: 0px;
`

export const NoBorderInput = styled(Input)`
  margin-top: 10px;
  border: none;
`
export const NoBorderTextarea = styled(Textarea)`
  margin-top: 40px;
  border: none;
`

export const HeaderImage = styled(Image)`
  height: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 80%;
    height: auto;
  }
`

export const CTAFlex = styled(Flex)`
  gap: 20px;
  margin: 20px 0 0 0;
  flex-wrap: nowrap;
  align-items: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    flex-wrap: wrap;
  }
`
