import { StyledLayout, CoreContent } from './Layout.Styled'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import ResponsiveState from './ResponsiveState/ResponsiveState'
// import Secure from './Secure/Secure'
import Social from '../../Social/Social'
// import PermissionLoad from './Permission/Load'

const Layout = ({ children, className, hideHeader = false, authRequired = false }) => {
  return (
    <StyledLayout className={className}>
      {/* <Secure authRequired={authRequired}> */}
      {/* <PermissionLoad authRequired={authRequired}> */}
      <ResponsiveState />
      <Social />
      {!hideHeader && <Header />}
      <CoreContent hideHeader={hideHeader}>{children}</CoreContent>
      <Footer />
      {/* </PermissionLoad> */}
      {/* </Secure> */}
    </StyledLayout>
  )
}

export default Layout
