import styled from 'styled-components'
import Flex from '../UI/Flex/Flex'
import Image from '../UI/Image/Image'
import Text from '../UI/Text/Text'

export const StyledEquipment = styled.div``

export const Center = styled.div`
  text-align: center;
  margin: 50px 0px;
`

export const Content = styled.div`
  height: 100vh;
  width: 100vw;
  background-image: url(${({ img }) => img});
  background-size: auto 100%;
  background-position: center right;
  background-repeat: no-repeat;
  padding: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    padding: 15px;
    background-image: none;
    height: auto;
    margin-bottom: 50px;
  }
`

export const MobileContentImage = styled(Image)`
  display: none;
  width: 100%;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: block;
  }
`

export const Headline = styled(Text)`
  font-size: 70px;
  color: ${({ theme }) => theme.color.font.secondary};
  font-family: 'bold';
  width: 60%;
  margin-top: 5%;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin-top: 0;
    font-size: ${({ theme }) => theme.size.lg};
    width: 100%;
  }
`

export const Box = styled.div`
  width: 50%;
  background-color: ${({ theme }) => theme.color.danger};
  padding: 50px;
  border-radius: 50px;
  margin-top: 50px;
  line-height: 35px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    width: 100%;
    border-radius: 25px;
    padding: 30px;
    line-height: 1.5;
  }
`

export const CustomText = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  line-height: 1.7;
`

export const SliderHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: 90px;
  font-family: 'bold';
  margin: 100px 0px;
  text-align: center;
  @media (max-width: ${() => '1800px'}) {
    margin: 450px 0px 100px 0;
    padding: 0 30px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    text-align: left;
    font-size: ${({ theme }) => theme.size.lg};
    margin: 0;
  }
`

export const SlideContent = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: top center;
  position: relative;
  overflow: hidden;
  border-radius: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    background-image: url(${({ imgMobile, img }) => (imgMobile ? imgMobile : img)});
  }
`

export const SlidePanel = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  padding: 30px;
  height: auto;
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.secondary};
  width: 700px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    padding: 15px;
    width: calc(100% - 30px);
    top: auto;
    bottom: 15px;
    left: 15px;
    right: auto;
  }
`

export const HeadlineWrapper = styled.div``
export const VehicleName = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  text-transform: uppercase;
  font-family: 'bold';
  font-size: 40px;
  line-height: 1;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    font-size: ${({ theme }) => theme.size.sm};
  }
`
export const LocationText = styled(Text)`
  margin-top: 30px;
  color: ${({ theme }) => theme.color.secondary};
  font-size: ${({ theme }) => theme.size.sm};
  text-align: justify;
  line-height: 1.5;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  }
`

export const CTA = styled.div`
  padding: 15px 0 10px 0;
  width: 80%;
  margin-left: 10%;
  background-color: ${({ theme, disabled }) =>
    disabled ? 'rgb(127, 127, 127)' : theme.color.danger};
  color: ${({ theme, disabled }) => (disabled ? 'rgb(96, 96, 96)' : theme.color.font.secondary)};
  border-radius: 50px;
  margin-top: 30px;
  text-transform: uppercase;
  font-size: ${({ theme }) => theme.size.lg};
  text-align: center;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    font-size: ${({ theme }) => theme.size.sm};
    padding: 10px;
  }
`

export const SliderWrapper = styled.div`
  padding: 0px 50px;
  margin: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    margin: 50px 0px;
    padding: 0px 15px;
  }
`

export const HeroFlex = styled(Flex)`
  display: none;
  gap: 55%;
  align-content: center;
  height: 100vh;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: flex;
  }
`

export const HeroImage = styled(Image)`
  height: 80px;
`

export const HeroText = styled(Text)`
  font-family: 'bold';
  font-size: 30px;
  text-align: center;
  color: ${({ theme }) => theme.color.font.secondary};
`

export const TextToggle = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  display: none;
  margin-top: 30px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  }
`

export const ToggleText = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.sm};
  display: inline-block;
  width: auto;
`

export const SubHeroImage = styled(Image)`
  height: 55px;
  @media (max-width: ${({ theme }) => theme.breakpoint.lg}) {
    height: 40px;
  }
`
