import styled from 'styled-components'
import Flex from '../UI/Flex/Flex'
import Image from '../UI/Image/Image'
import Text from '../UI/Text/Text'

export const StyledLocation = styled.div`
  padding-bottom: 50px;
`

export const Headline = styled(Text)`
  font-size: 90px;
  color: ${({ theme }) => theme.color.secondary};
  font-family: 'bold';
  margin: 50px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 40px;
  }
`

export const Center = styled.div`
  text-align: center;
`

export const SlideContent = styled.div`
  height: 100%;
  width: 100%;
  background-image: url(${({ img }) => img});
  background-size: cover;
  background-position: center center;
  position: relative;
  overflow: hidden;
  border-radius: 30px;
`

export const SlidePanel = styled.div`
  position: absolute;
  right: 40px;
  top: 40px;
  padding: 30px;
  height: auto;
  background-color: ${({ theme }) => theme.color.background};
  color: ${({ theme }) => theme.color.secondary};
  width: 700px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    left: 15px;
    right: auto;
    top: auto;
    bottom: 15px;
    width: calc(100% - 30px);
  }
`

export const CrossWrapper = styled.div``
export const CrossImage = styled(Image)`
  width: 75px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: none;
  }
`
export const HeadlineImage = styled(Image)`
  height: 30px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 19px;
  }
`
export const FlexWrapper = styled(Flex)`
  justify-content: left;
  gap: 40px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    gap: 0px;
  }
`
export const SlideHeadline = styled(Text)`
  color: ${({ theme }) => theme.color.backgroundWhite};
  text-transform: uppercase;
  font-family: 'bold';
  font-size: 40px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 18px;
  }
`
export const HeadlineWrapper = styled.div``
export const LocationName = styled(Text)`
  color: ${({ theme }) => theme.color.danger};
  text-transform: uppercase;
  font-family: 'bold';
  font-size: 40px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 18px;
  }
`
export const LocationText = styled(Text)`
  margin-top: 20px;
  color: ${({ theme }) => theme.color.secondary};
  font-size: ${({ theme }) => theme.size.sm};
  text-align: justify;
  line-height: 1.5;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    margin-top: 10px;
    display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
    line-height: 1.3;
    font-size: 14px;
  }
`

export const SliderWrapper = styled.div`
  padding: 0px 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0 15px;
  }
`

export const HeroMobileText = styled(Text)`
  position: absolute;
  top: 15%;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.color.secondary};
  font-size: 50px;
  font-family: 'bold';
  display: none;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: block;
  }
`

export const SeparatorImage = styled(Image)`
  height: 60px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 90%;
    height: auto;
  }
`

export const TextToggle = styled.div`
  text-align: center;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  display: none;
  margin-top: 10px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  }
`

export const ToggleText = styled(Text)`
  color: ${({ theme }) => theme.color.font.secondary};
  font-size: ${({ theme }) => theme.size.sm};
  display: inline-block;
  width: auto;
`
