import styled from 'styled-components'

export const StyledJob = styled.div`
  text-decoration: none;
  background-color: ${({ theme }) => theme.color.background};
  padding: 10px 20px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    flex-direction: column;
    align-items: flex-start;
    border-radius: 25px;
  }
`

export const Field = styled.div`
  color: ${({ theme }) => theme.color.font.secondary};
  ${({ bold, theme }) =>
    !bold &&
    `
    font-size: ${theme.size.sm};   
  `}
`

export const MetaData = styled.div`
  display: flex;
  gap: 10px;
`

export const Separator = styled.div`
  font-family: 'thin';
  font-size: ${({ theme }) => theme.size.sm};
  color: ${({ theme }) => theme.color.font.secondary};
`

export const Location = styled.div`
  border-radius: 30px;
  font-size: ${({ theme }) => theme.size.sm};
  text-align: center;
  padding: 6px 10px 2px;
  width: 25%;
  height: fit-content;
  border: thin solid ${({ theme }) => theme.color.danger};
  color: ${({ theme }) => theme.color.font.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
    box-sizing: border-box;
  }
`

export const Info = styled.div`
  width: 60%;
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    width: 100%;
  }
`

export const CTA = styled.a`
  display: flex;
  height: 30px;
  width: 40px;
  background-image: url(${({ src }) => src});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  &:hover {
    background-image: url(${({ srcHover }) => srcHover});
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.sm}) {
    margin-left: auto;
    margin-right: auto;
  }
`
