import Container from '../UI/Container/Container'
import {
  Anchor,
  Header,
  StyledImprint,
  H3,
  H2,
  P,
  Strong,
  Content,
  HeroFlex,
  HeroImage,
} from './Imprint.Styled'
import Layout from '../UI/Layout/Layout'
import { useSelector } from 'react-redux'
import hero from '../../assets/image/imprint/hero.jpg'
import rettungsdienst from '../../assets/image/logo-rettungsdienst.png'
import kreisverband from '../../assets/image/logo-kreisverband.png'
import Hero from '../UI/Hero/Hero'

const Imprint = () => {
  const translation = useSelector((s) => s.translation)
  return (
    <Layout>
      <StyledImprint>
        <Hero img={hero}>
          <HeroFlex>
            <HeroImage src={rettungsdienst} />
            <HeroImage src={kreisverband} />
          </HeroFlex>
        </Hero>
        <Container>
          <Content>
            <Header>{translation.imprint.headline}</Header>
            <H3>Anbieterkennung nach § 5 TMG</H3>

            <P>
              DRK-Kreisverband Coesfeld e.V.
              <br />
              Bahnhofstraße 128
              <br />
              48653 Coesfeld
              <br />
              Telefon: 02541 9442-1000
              <br />
              Telefax: 02541 9442-1099
            </P>

            <P>eMail: kgst(at)drk-coe.de</P>

            <P>Haben Sie Fragen? Dann wenden Sie sich bitte an unseren Webmaster.</P>

            <H3>Vertretungsberechtigte</H3>
            <P>Der DRK-Kreisverband Coesfeld e.V. wird gesetzlich vertreten durch:</P>

            <P>Christoph Schlütermann, Vorstand</P>

            <H3>Vereinsregister</H3>
            <P>
              Der DRK-Kreisverband Coesfeld e.V. ist im Vereinsregister beim Amtsgericht Coesfeld
              unter der Registernummer VR 142 eingetragen.
            </P>

            <H3>Steuerliche Angaben</H3>
            <P>USt-Identnr.: DE124470930</P>

            <H2>Haftungsausschluss</H2>
            <H3>1. Inhalt der Seiten</H3>
            <P>
              Der DRK-Kreisverband Coesfeld e.V. übernimmt keinerlei Gewähr für die Aktualität,
              Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Inhalte.
              Haftungsansprüche gegen den DRK-Kreisverband Coesfeld e.V., welche sich auf Schäden
              materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der
              dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger
              Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des
              DRK-Kreisverband Coesfeld e.V. kein nachweislich vorsätzliches oder grob fahrlässiges
              Verschulden vorliegt.
            </P>

            <H3>2. Verweise und Links</H3>
            <P>
              Die Verantwortlichkeit des Diensteanbieters beschränkt sich gemäß § 7 Abs. 1 TMG
              ausschließlich auf die vom Diensteanbieter bereitgehaltenen eigenen Inhalte. Darüber
              hinaus enthält die Website auch entsprechend gekennzeichnete Verweise oder Links auf
              Websites Dritter. Auf die aktuelle und zukünftige Gestaltung, die Inhalte oder die
              Urheberschaft der verlinkten/verknüpften Seiten hat der DRK-Kreisverband Coesfeld e.V.
              keinerlei Einfluss. Für den Inhalt der Seite ist allein der jeweilige Anbieter der
              Seite verantwortlich, weshalb der DRK-Kreisverband Coesfeld e.V. diesbezüglich keine
              Gewähr übernimmt.
            </P>
            <P>
              Der DRK-Kreisverband Coesfeld e.V. erklärt hiermit ausdrücklich, dass zum Zeitpunkt
              der Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar
              waren. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ohne konkrete
              Anhaltspunkte einer Rechtsverletzung ist jedoch nicht zumutbar. Bei Bekanntwerden von
              Rechtsverletzungen werden derartige Links umgehend entfernt.
            </P>

            <H3>3. Urheber- und Kennzeichenrecht</H3>
            <P>
              Der DRK-Kreisverband Coesfeld e.V. ist bestrebt, in allen Publikationen die
              Urheberrechte der verwendeten Bilder, Grafiken, Tondokumente, Videosequenzen und Texte
              zu beachten, von ihm selbst erstellte Bilder, Grafiken, Tondokumente, Videosequenzen
              und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videosequenzen und
              Texte zurückzugreifen.
            </P>
            <P>
              Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten
              Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils
              gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen
              Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass
              Markenzeichen nicht durch Rechte Dritter geschützt sind!
            </P>
            <P>
              Das Copyright für veröffentlichte, vom DRK-Kreisverband Coesfeld e.V. selbst erstellte
              Objekte bleibt allein beim Autor der Seiten. Eine Vervielfältigung, Bearbeitung,
              Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechts
              solcher Grafiken, Tondokumente, Videosequenzen und Texte in anderen elektronischen
              oder gedruckten Publikationen bedürfen der schriftlichen Zustimmung des Autors.
              Downloads und Kopien sind nur für den privaten, nicht aber den kommerziellen Gebrauch
              gestattet.
            </P>

            <H3>4. Datenschutz</H3>
            <P>
              Sofern innerhalb der Website die Möglichkeit zur Eingabe persönlicher oder
              geschäftlicher Daten (Mailadressen, Namen, Anschriften) besteht, so erfolgt die
              Preisgabe dieser Daten seitens des Nutzers auf ausdrücklich freiwilliger Basis. Die
              Inanspruchnahme und ggf. Bezahlung aller angebotenen Dienste ist - soweit technisch
              möglich und zumutbar - auch ohne Angabe solcher Daten bzw. unter Angabe anonymisierter
              Daten oder eines Pseudonyms gestattet. Eine Weiterleitung an Dritte erfolgt nicht ohne
              ausdrückliche Zustimmung des Users.
            </P>
            <P>
              Die Nutzung der im Rahmen des Impressums oder vergleichbarer Angaben veröffentlichten
              Kontaktdaten wie Postanschriften, Telefon- und Faxnummern sowie Emailadressen durch
              Dritte zur Übersendung von nicht ausdrücklich angeforderten Informationen ist nicht
              gestattet. Rechtliche Schritte gegen die Versender von sogenannten Spam-Mails bei
              Verstößen gegen dieses Verbot sind ausdrücklich vorbehalten.
            </P>

            <H3>5. Rechtswirksamkeit dieses Haftungsausschlusses</H3>
            <P>
              Dieser Haftungsausschluss ist als Teil des Informationsangebotes zu betrachten, von
              dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen
              dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig
              entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und
              ihrer Gültigkeit davon unberührt.
            </P>

            <H3>6. Hinweis auf EU-Streitschlichtung</H3>
            <P>
              Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS)
              bereit: ec.europa.eu/consumers/odr/{' '}
            </P>

            <P>
              Hinweis: Dieser Link führt auf die Seite eines anderen Anbieters und Sie verlassen
              unsere Webseite.
            </P>

            <P>Unsere E-Mail-Adresse finden Sie oben im Impressum</P>

            <P>
              Hinweis auf Verbraucherstreitbeilegung gemäß VSBG
              <br />
              Der DRK-Kreisverband Coesfeld e.V. nimmt nicht an Streitbeilegungsverfahren vor einer
              Streitbeilegungsstelle teil.
            </P>

            <P>
              siehe auch <Anchor href="/datenschutz">„Datenschutz“</Anchor>
            </P>

            <P>
              <Strong>Alle Inhalte und Fotos sind urheberrechtlich geschützt.</Strong>
            </P>
          </Content>
        </Container>
      </StyledImprint>
    </Layout>
  )
}

export default Imprint
