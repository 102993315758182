import styled from 'styled-components'
import HeroText from '../UI/Hero/HeroText/HeroText'
import Image from '../UI/Image/Image'
import Text from '../UI/Text/Text'

export const StyledAboutUs = styled.div``

export const Center = styled.div`
  text-align: center;
`
export const CustomHeroText = styled(HeroText)`
  bottom: 100px;
  font-size: 90px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 50px;
  }
`

export const SeparatorImage = styled(Image)`
  height: 50px;
  margin: 100px 0px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: auto;
    width: 70%;
    margin: 50px 0px;
  }
`

export const VideoHeadline = styled(Text)`
  margin: 0 0 50px 0;
  color: ${({ theme }) => theme.color.secondary};
  font-size: 90px;
  font-family: bold;
  text-align: center;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    font-size: 35px;
  }
`

export const Headline = styled(Text)`
  font-family: 'bold';
  color: ${({ theme }) => theme.color.secondary};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0 10px;
  }
`

export const CustomText = styled(Text)`
  margin: ${({ margin }) => margin || '25px 0 0 0'};
  color: ${({ theme }) => theme.color.secondary};
  size: ${({ theme }) => theme.size.sm};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    padding: 0 10px;
  }
`
