import styled from 'styled-components'
import Image from '../../UI/Image/Image'
import headerImage from '../../../assets/image/BG_Desktop.jpg'
import headerImageMobile from '../../../assets/image/BG_Mobil.jpg'

export const StyledFirstScreen = styled.div`
  height: 105vh;
  position: relative;
  background-image: url(${headerImage});
  background-size: cover;
  background-position: center top;
  background-repeat: no-repeat;

  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    background-image: url(${headerImageMobile});
  }
`

export const LogoWrapper = styled.div`
  text-align: right;
  padding: 25px;
`

export const Logo = styled(Image)`
  height: 50px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 30px;
  }
`

export const Headline = styled.div`
  text-align: center;
  padding-top: 125px;
`
export const HeadlineImage = styled(Image)`
  height: 100px;
  &:first-child {
    margin-right: 50px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    height: 30px;
    &:first-child {
      margin-right: 20px;
    }
  }
`

export const Hint = styled.div`
  margin-top: 30vw;
  text-align: center;
  width: 100%;
  position: absolute;
  bottom: 150px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    position: absolute;
    text-align: center;
    display: block;
    left: 10%;
    height: auto;
    width: 80%;
    bottom: 100px;
  }
`

export const HintImage = styled(Image)`
  width: 40vw;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    width: 75vw;
  }
`

export const CTAWrapper = styled.div`
  margin-top: 50px;
  text-align: center;
`
export const CTA = styled.a`
  color: ${({ theme }) => theme.color.secondary};
  padding: 10px 60px;
  border-radius: 20px;
  border: 2px solid ${({ theme }) => theme.color.secondary};
  display: inline-block;
  font-size: 22px;
  text-decoration: none;
  font-family: 'regular';
  @media (max-width: ${({ theme }) => theme.breakpoint.md}) {
    color: ${({ theme }) => theme.color.secondary};
    padding: 10px 30px;
    font-size: 15px;
    border-radius: 15px;
  }
`
