import { StyledFeed, Tile, TileImage } from './Feed.Styled'
import { getUUID } from '../../../../utility'

const Feed = ({ items }) => {
  return (
    <StyledFeed>
      {items.map(({ media_url, permalink }) => (
        <Tile key={getUUID()} href={permalink} target="_blank">
          <TileImage img={media_url} />
        </Tile>
      ))}
    </StyledFeed>
  )
}

export default Feed
