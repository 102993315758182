import styled from 'styled-components'
import Text from '../../Text/Text'

export const StyledHeroText = styled.div`
  position: absolute;
  bottom: ${({ bottom }) => bottom};
  top: ${({ top }) => top};
  font-family: 'headline';
  width: 100%;
`
export const CustomText = styled(Text)`
  font-size: ${({ size }) => size || '100px'};
  text-align: center;
  color: ${({ theme }) => theme.color.secondary};
  font-family: 'bold';
  text-transform: uppercase;
`
