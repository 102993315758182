import styled from 'styled-components'

export const StyledImage = styled.img`
  height: ${({ height }) => height || 'unsert'};
  width: ${({ width }) => width || 'unsert'};

  max-height: ${({ maxHeight }) => maxHeight || 'unsert'};
  max-width: ${({ maxWidth }) => maxWidth || 'unsert'};

  margin: ${({ margin }) => margin || '0px'};

  ${({ onClick }) => (onClick ? 'cursor: pointer;' : '')}
`
