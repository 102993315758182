import { StyledBottom, Box, BoxText } from './Bottom.Styled'
import backgroundImage from '../../../assets/image/home/bottom.jpg'
// import backgroundImageMobile from '../../../assets/image/home/bottomMobile.png'
import { useSelector } from 'react-redux'

const Bottom = () => {
  const translation = useSelector((s) => s.translation)
  const theme = useSelector((s) => s.theme)

  return (
    <StyledBottom img={backgroundImage}>
      <Box>
        <BoxText text={translation.home.bottom.box.headline} font={'bold'} margin={'0 0 20px 0'} />
        <BoxText text={translation.home.bottom.box.text1} size={theme.size.sm} />
      </Box>
    </StyledBottom>
  )
}

export default Bottom
